import React, { useState, useEffect, useRef } from 'react';
import './Registration3.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FileUploadForm from './FileUploadForm_old'
import { AccountCircle, AccountBalance, BusinessCenter } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import { useVendorContext } from './VendorContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // can be used later
//import SupportAgentIcon from '@mui/icons-material/SupportAgent'; // can be used later

const Submit = () => {
  const { vendorNo } = useVendorContext();
  // Get token
  const csrfToken = localStorage.getItem('csrfToken');
  console.log("CSRF Token value is: ", csrfToken);
  const navigate = useNavigate();
  console.log('Received vendorNo in Registration3:', vendorNo);

  let initialIntroductionData = {
    businessRegistration: '',
    vendorType: '',
    organisationType: '',
    organisationName: '',
    vendorPrimaryMobileCountryCode: '',
    vendorPrimaryMobileCountryCodeDescription: '',
    vendorPrimaryMobile: '',
    district: '',
    vendorPrimaryEmail: '',
    contactPersonSalutation: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonDesignation: '',
    registeredOfficeAddressCity: '',
    registeredOfficeAddressCountry: '',
    registeredOfficeAddressCountryDescription: '',
    registeredOfficeAddressPincode: '',
    registeredOfficeAddressState: '',
    registeredOfficeAddressStateDescription: '',
    registeredOfficeAddressBuilding: '',
    registeredOfficeAddressStreet: '',
    briefIntroduction: '',
    // briefIntroduction : introductionData?.briefIntroduction || '',
    financeManagerName: '',
    financeManagerPhoneNumberCountryCode: '+91',
    financeManagerPhoneNumberCountryCodeDescription: '',
    financeManagerPhoneNumber: '',
    financeManagerEmail: '',
  }

  const initialKYVData = {
    countryOfTaxResidency: '',
    countryOfTaxResidencyLabel: '',
    dateOfIncorporation: '',
    PANNumber: '',
    typeOfWork: '',
    CINNumber: '',
    CINStatus: 'Filled',
    isCINNotRegisteredChecked: false,
    isCINNotApplicableChecked: false,
    GSTNumber: '',
    GSTStatus: 'Filled',
    isGSTNotApplicableChecked: false,
    isGSTNotRegisteredChecked: false,
    radioOption: '',
    udyamNumber: '',
    udyamPhoneNumber: '',
    udyamEmail: '',
    msmeNumber: '',
    msmePhoneNumber: '',
    otpRadioOption: '',
    udyamPhoneNumberOTP: '',
    udyamEmailOTP: '',
    msmePhoneNumberOTP: '',
    msmeEmail: '',
    msmeEmailOTP: '',
    EPFNumber: '',
    EPFStatus: 'Filled',
    isEPFNotApplicableChecked: false,
    isEPFNotRegisteredChecked: false,
    ESICNumber: '',
    ESICStatus: 'Filled',
    isESICNotApplicableChecked: false,
    isESICNotRegisteredChecked: false,
    ESICRelatedDistrict: '',
    TANNumberCountryCode: '',
    TANNumber: '',
    TANStatus: 'Filled',
    isTANNotApplicableChecked: false,
    TANMobileNumber: '',
  };

  const initialKYVErrorMessages = {
    PANError: '',
    CINError: '',
    GSTError: '',
    UDYAMError: '',
    MSMEError: '',
    EPFError: '',
    ESICError: '',
    TANError: ''
  };

  const initialbankAccountData = {
    bankAccountNumber: '',
    bankAccountHolderName: '',
    bankName: '',
    branchName: '',
    swiftCode: '',
    bankAddressStreet: '',
    bankAddressRegion: '',
    bankAddressCity: '',
    bankID: '',
    bankCountryKey: '',
    bankControlKey: '',
  };

  const [saveData, setSaveData] = useState({
    isIntroductionData: false,
    isKYVData: false,
    isVerificationData: false,
    isBankData: false,
  });

  const { isIntroductionData, isKYVData, isBankData } = saveData;

  let [introductionData, setIntroductionData] = useState(initialIntroductionData);
  const [kyvData, setKYVData] = useState(initialKYVData);
  const [errorMessages, setErrorMessages] = useState(initialKYVErrorMessages);
  const [bankAccountData, setBankAccountData] = useState(initialbankAccountData);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [data, setData] = useState([]);
  const [isIntroductionDataSaved, setIsIntroductionDataSaved] = useState(false);
  const [isIntroductionDataResetted, setIsIntroductionDataResetted] = useState(false);
  const [isKYVDataSaved, setIsKYVDataSaved] = useState(false);
  const [isKYVDataResetted, setIsKYVDataResetted] = useState(false);
  const [isBankDataSaved, setIsBankDataSaved] = useState(false);
  const [isBankDataResetted, setIsBankDataResetted] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [selectedAPICountryValue, setSelectedAPICountryValue] = useState('');
  const [selectedAPICountryOfTaxResidencyValue, setSelectedAPICountryOfTaxResidencyValue] = useState('');
  const [selectedAPIStateValue, setSelectedAPIStateValue] = useState('');
  const [selectedPrimaryVendorCountryCode, setSelectedPrimaryVendorCountryCode] = useState('');
  const [selectedManagerPhoneCountryCode, setSelectedManagerPhoneCountryCode] = useState('');
  const [isEditMode, setIsEditMode] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [steps, setSteps] = useState([
    { id: 1, label: 'Vendor Details', icon: <BusinessCenter />, isCompleted: false },
    { id: 2, label: 'KYV Details', icon: <AccountCircle />, isCompleted: false },
    { id: 3, label: 'Bank Details', icon: <AccountBalance />, isCompleted: false },
    { id: 4, label: 'Documents', icon: <DescriptionIcon />, isCompleted: false },
  ]);
  const markStepAsCompleted = (stepNumber) => {
    if (!completedSteps.includes(stepNumber)) {
      setCompletedSteps([...completedSteps, stepNumber]);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepNumber ? { ...step, isCompleted: true } : step
        )
      );
    }
  };
  const handleStepperClick = (stepNumber, bool) => {
    if (bool === false) {
      setCurrentStep(stepNumber);
      markStepAsCompleted(stepNumber);
    }

    else {
      const isAllPreviousStepsCompleted = steps
        .filter((step) => step.id <= stepNumber)
        .every((step) => completedSteps.includes(step.id));

      if (isAllPreviousStepsCompleted) {
        setCurrentStep(stepNumber);
        markStepAsCompleted(stepNumber);
      } else {
        // Show an error message or prevent navigation
      }
    }
  };

  const removeStepFromCompleted = (stepNumber) => {
    if (completedSteps.includes(stepNumber)) {
      setCompletedSteps(completedSteps.filter((step) => step !== stepNumber));
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepNumber ? { ...step, isCompleted: false } : step
        )
      );
    }
  };

  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [vendorNumber, setVendorNumber] = useState(0);
  const [companyCode, setCompanyCode] = useState(0);
  const [plant, setPlant] = useState(0);
  const [existingData, setExistingData] = useState(null);
  const [distinctCountryMap, setDistinctCountryMap] = useState(new Map());
  const [distinctCountryCodeMap, setDistinctCountryCodeMap] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const handleSetIntroductionData = (updatedData) => {
    setIntroductionData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  console.log("Vendor No from params is: ", vendorNo);
  const selectedCountryRef = useRef();
  const [sharedData, setSharedData] = useState(null);
  const [sharedCountryCodeData, setSharedCountryCodeData] = useState(null);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  // useEffect(() => {
  //   if (!hasFetchedData) {
  //     const fetchData = async () => {
  //       const currentVendorNo = vendorNo;
  //       try {
  //         if (!currentVendorNo) {
  //           console.log('VendorNo is not available yet. Skipping API call.');
  //           return;
  //         }
  //         const apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${vendorNo}`;
  //         setLoading(true);
  //         const response = await fetch(apiUrl, {
  //           method: 'GET',
  //           headers: {
  //             'Authorization': 'Basic ' + base64Credentials,
  //             // 'Access-Control-Allow-Origin': '*',
  //             // 'Access-Control-Allow-Headers': '*',
  //             // 'Access-Control-Allow-Methods': '*',
  //             'Content-Type': 'application/json',
  //             'x-csrf-token': 'fetch',
  //           },
  //         });
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }

  //         const apiData = await response.json();
  //         console.log("API response Headers are: ", response);
  //         // Accessing headers
  //         const csrfToken = response.headers.get('x-csrf-token');
  //         console.log("CSRF Token is:", csrfToken);
  //         const csrfToke = response.headers.get('X-CSRF-TOKEN');
  //         console.log("CSRF Token is:", csrfToke);
  //         setExistingData(apiData);
  //         setLoading(false);
  //         console.log("ApiData is: ", apiData);
  //         console.log(response.headers['x-csrf-token']);
  //         if (apiData.VEND_STATUS === "REQUESTED" || apiData.VEND_STATUS === "CREATED" || apiData.VEND_STATUS === "INAPP" || apiData.VEND_STATUS === "APPROVED") {
  //           setIsEditMode(false);
  //           setSaveData({ ...saveData, isIntroductionData: true, isKYVData: true, isVerificationData: true, isBankData: true });
  //         }
  //         let registeredOfficeCountry = apiData.COUNTRY;
  //         let registeredOfficeState = apiData.STATE;
  //         let selectedCountryOfTaxReseidencyValue = apiData.COUNTRY_TAX_RESID;
  //         let vendorMobileCountryCode = apiData.TELEFTO;
  //         let managerMobileCountryCode = apiData.CNTRY_FIN_MANGR;
  //         console.log("registeredOfficeCountry: ", registeredOfficeCountry);
  //         console.log("registeredOfficeState: ", registeredOfficeState);
  //         console.log("countryOfTaxResidencyLabel: ", apiData.LANDX1);
  //         console.log("ESICStatus is: ", apiData.ESIC_STATUS);
  //         console.log("CINStatus is: ", apiData.CIN_STATUS);
  //         console.log("Date of Incop is: ", apiData.DATE_OF_INCORP);
  //         console.log("vendorType is: ", apiData.VENDOR_TYPE);
  //         setSelectedAPICountryOfTaxResidencyValue(selectedCountryOfTaxReseidencyValue);
  //         setSelectedAPICountryValue(registeredOfficeCountry);
  //         setSelectedAPIStateValue(registeredOfficeState);
  //         setSelectedPrimaryVendorCountryCode(vendorMobileCountryCode);
  //         setSelectedManagerPhoneCountryCode(managerMobileCountryCode);
  //         handleSetIntroductionData({
  //           businessRegistration: apiData.BUTXT,
  //           vendorType: apiData.VENDOR_TYPE,
  //           organisationType: apiData.ORG_TYPE,
  //           organisationName: apiData.VENDOR_COMP_NAME,
  //           vendorPrimaryEmail: apiData.VENDOR_P_EMAIL,
  //           vendorPrimaryMobileCountryCode: apiData.TELEFTO,
  //           vendorPrimaryMobile: apiData.VENDOR_P_MOBNO.toString(),
  //           contactPersonSalutation: apiData.NAMEOFCONT_SAL,
  //           contactPersonFirstName: apiData.BU_SORT1_TXT,
  //           contactPersonLastName: apiData.BU_SORT2_TXT,
  //           contactPersonDesignation: apiData.CONT_PERSON_DES,
  //           registeredOfficeAddressPincode: apiData.POST_CODE1 === 0 ? '' : apiData.POST_CODE1.toString(),
  //           registeredOfficeAddressCity: apiData.CITY1,
  //           district: apiData.DISTRICT_REG,
  //           registeredOfficeAddressState: apiData.STATE,
  //           registeredOfficeAddressStateDescription: apiData.BEZEI,
  //           registeredOfficeAddressCountry: apiData.COUNTRY,
  //           registeredOfficeAddressCountryDescription: apiData.LANDX,
  //           registeredOfficeAddressBuilding: apiData.BUILDING_BLOCK,
  //           registeredOfficeAddressStreet: apiData.STREET,
  //           briefIntroduction: apiData.BRIEF_INTRO,
  //           financeManagerName: apiData.NAMEOF_FIN_MANGR,
  //           financeManagerEmail: apiData.EMAILOF_FIN_MANGR,
  //           financeManagerPhoneNumberCountryCode: apiData.CNTRY_FIN_MANGR,
  //           financeManagerPhoneNumber: apiData.PHNOOF_FIN_MANGR === 0 ? '' : apiData.PHNOOF_FIN_MANGR.toString(),
  //         });
  //         setKYVData((prevData) => ({
  //           ...prevData,
  //           countryOfTaxResidency: apiData.COUNTRY_TAX_RESID,
  //           countryOfTaxResidencyLabel: apiData.LANDX1,
  //           dateOfIncorporation: apiData.DATE_OF_INCORP,
  //           PANNumber: apiData.PANNO,
  //           typeOfWork: apiData.CAT_TYPE_OF_WORK,
  //           CINNumber: apiData.CIN_NUM,
  //           CINStatus: apiData.CIN_STATUS,
  //           GSTNumber: apiData.GSTNO,
  //           GSTStatus: apiData.GST_STATUS,
  //           radioOption: apiData.MSME_UDAYAM,
  //           udyamNumber: apiData.UDAYAM_NUM,
  //           msmeNumber: apiData.MSME_NUM,
  //           EPFNumber: apiData.EPF_NUM,
  //           EPFStatus: apiData.EPF_STATUS,
  //           ESICNumber: apiData.ESIC_NUM === 0 ? '' : apiData.ESIC_NUM,
  //           ESICRelatedDistrict: apiData.DISTRICT,
  //           ESICStatus: apiData.ESIC_STATUS,
  //           TANNumber: apiData.TAN_NUM,
  //           TANStatus: apiData.TAN_STATUS,
  //           TANMobileNumber: apiData.TAN_MOBILE === 0 ? '' : apiData.TAN_MOBILE.toString(),
  //         }));
  //         console.log("API radio Data is: ", apiData.MSME_UDAYAM);
  //         console.log("Selected Country in API is: ", apiData.COUNTRY_TAX_RESID);
  //         selectedCountryRef.current = apiData.COUNTRY_TAX_RESID;

  //         setBankAccountData((prevData) => ({
  //           ...prevData,
  //           swiftCode: apiData.BANKL,
  //           bankName: apiData.BANKA,
  //           bankAccountHolderName: apiData.KOINH,
  //           bankAccountNumber: apiData.BANKN,
  //           branchName: apiData.BANK_BRANCH,
  //           bankAddress: apiData.BANK_ADDRESS,
  //           bankAddressStreet: apiData.STRAS,
  //           bankAddressRegion: apiData.PROVZ,
  //           bankAddressCity: apiData.ORT01,
  //           bankID: apiData.BKVID,
  //           bankControlKey: apiData.BKONT,
  //           bankCountryKey: apiData.BANKS,
  //         }));
  //         const contStateArray = apiData.RAPI_CONT_STATE || [];
  //         console.log("apiData.RAPI_CONT_STATE", apiData.RAPI_CONT_STATE);
  //         setSharedData(contStateArray);

  //         const contCountryCodeArray = apiData.MOB_CODES || [];
  //         console.log("apiData.MOB_CODES", apiData.MOB_CODES);
  //         setSharedCountryCodeData(contCountryCodeArray);
  //         setVendorNumber(apiData.VENDOR_NO);

  //         setCompanyCode(apiData.BUKRS);

  //         setPlant(apiData.WERKS);
  //         console.log('Attempting API call with vendorNo:', vendorNo);
  //       } catch (error) {
  //         console.log("Ended up with and error and loading ")
  //         setLoading(false);
  //         if (error instanceof TypeError) {
  //           console.error('TypeError:', error.message);
  //           alert('Type Error :', error.message);
  //         } else if (error instanceof SyntaxError) {
  //           console.error('SyntaxError:', error.message);
  //           alert('SyntaxError:', error.message);
  //         } else {
  //           console.error('Unexpected error:', error);
  //           alert('Error while fetching data:', error);
  //         }
  //       }

  //       console.log('Attempting API after call with vendorNo:', vendorNo);
  //     };
  //     fetchData();
  //   }

  // }, [vendorNo
  //   // , saveData, base64Credentials, hasFetchedData
  // ]);

  useEffect(() => {
    if (!hasFetchedData) {
      const fetchData = async () => {
        const currentVendorNo = vendorNo;
        try {
          if (!currentVendorNo) {
            console.log('VendorNo is not available yet. Skipping API call.');
            return;
          }
          // const apiUrl = 'http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor';

          setLoading(true);
          const apiUrl = `${process.env.REACT_APP_API_URL_USER}${vendorNo}`;
          const response = await axios.get(apiUrl, {
            headers: {
              'Authorization': 'Basic ' + base64Credentials,
              // 'Access-Control-Allow-Origin', 'Access-Control-Allow-Headers', 
              // and 'Access-Control-Allow-Methods' are handled by the server, not the client
              'Content-Type': 'application/json',
              // 'X-CSRF-Token': 'Fetch',
            },

          })
          // .then((response) => {
          //   // const csrfToken = response.headers['x-csrf-token'];
          //   // localStorage.setItem('csrfToken', csrfToken);
          //   console.log("csrf token from .then response is: ", csrfToken); });
          console.log("API URL is", apiUrl);
          const apiData = response.data;
          console.log("response is: ", response);
          console.log("API response Headers are: ", response.headers);
          const csrfToken = response.headers['x-csrf-token'];
          console.log("CSRF token is: ", csrfToken);
          setExistingData(apiData);
          setLoading(false);
          console.log("ApiData is: ", apiData);

          if (apiData.VEND_STATUS === "REQUESTED" || apiData.VEND_STATUS === "CREATED" || apiData.VEND_STATUS === "INAPP" || apiData.VEND_STATUS === "APPROVED") {
            setIsEditMode(false);
            setSaveData({ ...saveData, isIntroductionData: true, isKYVData: true, isVerificationData: true, isBankData: true });
          }
          let registeredOfficeCountry = apiData.COUNTRY;
          let registeredOfficeState = apiData.STATE;
          let selectedCountryOfTaxResidencyValue = apiData.COUNTRY_TAX_RESID;
          let vendorMobileCountryCode = apiData.TELEFTO;
          let managerMobileCountryCode = apiData.CNTRY_FIN_MANGR;
          console.log("registeredOfficeCountry: ", registeredOfficeCountry);
          console.log("registeredOfficeState: ", registeredOfficeState);
          console.log("countryOfTaxResidencyLabel: ", apiData.LANDX1);
          console.log("ESICStatus is: ", apiData.ESIC_STATUS);
          console.log("CINStatus is: ", apiData.CIN_STATUS);
          console.log("Date of Incop is: ", apiData.DATE_OF_INCORP);
          console.log("vendorType is: ", apiData.VENDOR_TYPE);
          setSelectedAPICountryOfTaxResidencyValue(selectedCountryOfTaxResidencyValue);
          setSelectedAPICountryValue(registeredOfficeCountry);
          setSelectedAPIStateValue(registeredOfficeState);
          setSelectedPrimaryVendorCountryCode(vendorMobileCountryCode);
          setSelectedManagerPhoneCountryCode(managerMobileCountryCode);
          handleSetIntroductionData({
            businessRegistration: apiData.BUTXT,
            vendorType: apiData.VENDOR_TYPE,
            organisationType: apiData.ORG_TYPE,
            organisationName: apiData.VENDOR_COMP_NAME,
            vendorPrimaryEmail: apiData.VENDOR_P_EMAIL,
            vendorPrimaryMobileCountryCode: apiData.TELEFTO,
            vendorPrimaryMobile: apiData.VENDOR_P_MOBNO ? apiData.VENDOR_P_MOBNO.toString() : '', // NUMC10
            contactPersonSalutation: apiData.NAMEOFCONT_SAL,
            contactPersonFirstName: apiData.BU_SORT1_TXT,
            contactPersonLastName: apiData.BU_SORT2_TXT,
            contactPersonDesignation: apiData.CONT_PERSON_DES,
            // registeredOfficeAddressPincode: apiData.POST_CODE1 === 0 ? '' : apiData.POST_CODE1.toString(), // CHAR10
            registeredOfficeAddressPincode: apiData.POST_CODE1 ? apiData.POST_CODE1.toString() : '',
            registeredOfficeAddressCity: apiData.CITY1,
            district: apiData.DISTRICT_REG,
            registeredOfficeAddressState: apiData.STATE,
            registeredOfficeAddressStateDescription: apiData.BEZEI,
            registeredOfficeAddressCountry: apiData.COUNTRY,
            registeredOfficeAddressCountryDescription: apiData.LANDX,
            registeredOfficeAddressBuilding: apiData.BUILDING_BLOCK,
            registeredOfficeAddressStreet: apiData.STREET,
            briefIntroduction: apiData.BRIEF_INTRO || '',
            financeManagerName: apiData.NAMEOF_FIN_MANGR,
            financeManagerEmail: apiData.EMAILOF_FIN_MANGR,
            financeManagerPhoneNumberCountryCode: apiData.CNTRY_FIN_MANGR,
            financeManagerPhoneNumber: apiData.PHNOOF_FIN_MANGR ? apiData.PHNOOF_FIN_MANGR.toString() : '', //NUMC10 
          });
          setKYVData((prevData) => ({
            ...prevData,
            countryOfTaxResidency: apiData.COUNTRY_TAX_RESID,
            countryOfTaxResidencyLabel: apiData.LANDX1,
            dateOfIncorporation: apiData.DATE_OF_INCORP,
            PANNumber: apiData.PANNO,
            typeOfWork: apiData.CAT_TYPE_OF_WORK,
            CINNumber: apiData.CIN_NUM,
            CINStatus: apiData.CIN_STATUS,
            GSTNumber: apiData.GSTNO,
            GSTStatus: apiData.GST_STATUS,
            radioOption: apiData.MSME_UDAYAM,
            udyamNumber: apiData.UDAYAM_NUM,
            msmeNumber: apiData.MSME_NUM,
            EPFNumber: apiData.EPF_NUM,
            EPFStatus: apiData.EPF_STATUS,
            ESICNumber: apiData.ESIC_NUM === 0 ? '' : apiData.ESIC_NUM,
            ESICRelatedDistrict: apiData.DISTRICT,
            ESICStatus: apiData.ESIC_STATUS,
            TANNumber: apiData.TAN_NUM,
            TANStatus: apiData.TAN_STATUS,
            TANMobileNumber: apiData.TAN_MOBILE ? apiData.TAN_MOBILE.toString() : '',
          }));
          console.log("API radio Data is: ", apiData.MSME_UDAYAM);
          console.log("Selected Country in API is: ", apiData.COUNTRY_TAX_RESID);
          selectedCountryRef.current = apiData.COUNTRY_TAX_RESID;

          setBankAccountData((prevData) => ({
            ...prevData,
            swiftCode: apiData.BANKL,
            bankName: apiData.BANKA,
            bankAccountHolderName: apiData.KOINH,
            bankAccountNumber: apiData.BANKN,
            branchName: apiData.BANK_BRANCH,
            bankAddress: apiData.BANK_ADDRESS,
            bankAddressStreet: apiData.STRAS,
            bankAddressRegion: apiData.PROVZ,
            bankAddressCity: apiData.ORT01,
            bankID: apiData.BKVID,
            bankControlKey: apiData.BKONT,
            bankCountryKey: apiData.BANKS,
          }));
          const contStateArray = apiData.RAPI_CONT_STATE || [];
          console.log("apiData.RAPI_CONT_STATE", apiData.RAPI_CONT_STATE);
          setSharedData(contStateArray);

          const contCountryCodeArray = apiData.MOB_CODES || [];
          console.log("apiData.MOB_CODES", apiData.MOB_CODES);
          setSharedCountryCodeData(contCountryCodeArray);
          setVendorNumber(apiData.VENDOR_NO);

          setCompanyCode(apiData.BUKRS);
          setPlant(apiData.WERKS);
          console.log('Attempting API call with vendorNo:', vendorNo);
        } catch (error) {
          console.log("Ended up with and error and loading ")
          setLoading(false);
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.message);
            alert('Axios Error: ' + error.message);
          } else {
            console.error('Unexpected error:', error);
            alert('Error while fetching data: ' + error);
          }
        }

        console.log('Attempting API after call with vendorNo:', vendorNo);
      };
      fetchData();
      //   async function fetchCsrfToken() {
      //     try {
      //       const response = await axios.get('http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=0000000543', {
      //         headers: {
      //           'X-CSRF-Token': 'Fetch',
      //           'Authorization': 'Basic ' + base64Credentials,
      //           'Content-Type': 'application/json',
      //            // SAP-specific header to request the CSRF token
      //         },
      //         // withCredentials: true // Include cookies if necessary
      //       });
      //       console.log('CSRF Token:', response.headers['x-csrf-token']);
      //       return response.headers['x-csrf-token'];
      //     } catch (error) {
      //       console.error('Error fetching CSRF token:', error);
      //     }
      //   }

      //   const abc=fetchCsrfToken()
      //   console.log("abc value is: ", abc);
      //   fetchData();
    }
  }, [vendorNo]);


  useEffect(() => {
    if (sharedData
      //&& sharedData !== prevSharedData.current
    ) {
      const countryMap = new Map();
      sharedData.forEach(item => {
        countryMap.set(item.RAPI_CLANDX50, item.RAPI_CLAND1);
      });
      setDistinctCountryMap(countryMap);
      const filteredData = sharedData.filter(item => item.RAPI_CLAND1 === selectedAPICountryValue);
      console.log("Filtered Data is: ", filteredData);
      setCountryData(filteredData);
      //prevSharedData.current = sharedData;
    }

  }, [sharedData, selectedAPICountryValue]);

  useEffect(() => {
    if (sharedCountryCodeData
      //&& sharedData !== prevSharedData.current
    ) {
      const countryMap = new Map();
      sharedCountryCodeData.forEach(item => {
        countryMap.set(item.MOB_COUNTRY, item.TELEFTO);
      });
      setDistinctCountryCodeMap(countryMap);
    }
  }, [sharedCountryCodeData]);

  const handleSelectCountryCodeChange = (event) => {
    const selectedCountryCodeValue = event.target.value;
    const name = event.target.name;
    const selectedCountryCodeDescription = event.target.options[event.target.selectedIndex].text;
    if (name === "vendorPrimaryMobileCountryCode") {
      setSelectedPrimaryVendorCountryCode(selectedCountryCodeValue);
      setIntroductionData({
        ...introductionData,
        vendorPrimaryMobileCountryCode: selectedCountryCodeValue,
        vendorPrimaryMobileCountryCodeDescription: selectedCountryCodeDescription,
      });

      console.log("Selected Country from API is: ", selectedCountryCodeValue);
      console.log(
        "introductionData.vendorPrimaryMobileCountryCode is: ",
        introductionData.vendorPrimaryMobileCountryCode
      );
      console.log(
        "introductionData.vendorPrimaryMobileCountryCode is: ",
        introductionData.vendorPrimaryMobileCountryCodeDescription
      );
    }
    else if (name === "financeManagerPhoneNumberCountryCode") {
      setSelectedManagerPhoneCountryCode(selectedCountryCodeValue);
      setIntroductionData({
        ...introductionData,
        // financeManagerPhoneNumberCountryCode: selectedCountryCodeValue,
        financeManagerPhoneNumberCountryCode: '+91',
        financeManagerPhoneNumberCountryCodeDescription: selectedCountryCodeDescription,
      });
      console.log(
        "introductionData.financeManagerPhoneNumberCountryCode is: ",
        introductionData.financeManagerPhoneNumberCountryCode
      );
      console.log(
        "introductionData.financeManagerPhoneNumberCountryCodeDescription is: ",
        introductionData.financeManagerPhoneNumberCountryCodeDescription
      );
    }
  };

  const handleSelectCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPICountryValue(selectedCountryValue);
    setIntroductionData({
      ...introductionData,
      registeredOfficeAddressCountry: selectedCountryValue,
      registeredOfficeAddressCountryDescription: selectedCountryDescription,
    });

    console.log("Selected Country from API is: ", selectedCountryValue);
    console.log(
      "introductionData.registeredOfficeAddressCountry is: ",
      introductionData.registeredOfficeAddressCountry
    );
    console.log(
      "introductionData.registeredOfficeAddressCountryDescription is: ",
      introductionData.registeredOfficeAddressCountryDescription
    );
  };

  const handleSelectCountryOfTaxResidencyChange = (event) => {
    const selectedCountryOfTaxResidencyValue = event.target.value;
    const selectedCountryOfTaxResidencyDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPICountryOfTaxResidencyValue(selectedCountryOfTaxResidencyValue);
    setKYVData({
      ...kyvData,
      countryOfTaxResidency: selectedCountryOfTaxResidencyValue,
      countryOfTaxResidencyLabel: selectedCountryOfTaxResidencyDescription,
    });

    console.log("Selected Country of Tax Residency from API is: ", selectedCountryOfTaxResidencyValue);
    console.log(
      "kyvData.countryOfTaxResidency is: ",
      kyvData.countryOfTaxResidency
    );
    console.log(
      "kyvData.countryOfTaxResidencyDescription is: ",
      kyvData.countryOfTaxResidencyLabel
    );
  };

  const handleSelectStateChange = (event) => {
    const selectedStateValue = event.target.value;
    const selectedStateDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPIStateValue(selectedStateValue);
    setIntroductionData({
      ...introductionData,
      registeredOfficeAddressState: selectedStateValue,
      registeredOfficeAddressStateDescription: selectedStateDescription,
    });

    console.log("Selected State from API is: ", selectedStateValue);
    console.log(
      "introductionData.registeredOfficeAddressState is: ",
      introductionData.registeredOfficeAddressState
    );
    console.log(
      "introductionData.registeredOfficeAddressStateDescription is: ",
      introductionData.registeredOfficeAddressStateDescription
    );
  };

  const fetchDataFromApi = async (e) => {
    const apiUrl = `${process.env.REACT_APP_API_SUBMIT_USER}${vendorNo}`;

    // const apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${vendorNo}`;
    console.log("This is existing Data: ");
    console.log(existingData);

    const updatedFields = {
      ...existingData,
      BUTXT: introductionData.businessRegistration,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
      VENDOR_TYPE: introductionData.vendorType,
      VENDOR_NO: vendorNumber,
      ORG_TYPE: introductionData.organisationType,
      VENDOR_COMP_NAME: introductionData.organisationName,
      NAMEOFCONT_SAL: introductionData.contactPersonSalutation,
      BU_SORT1_TXT: introductionData.contactPersonFirstName,
      BU_SORT2_TXT: introductionData.contactPersonLastName,
      CONT_PERSON_DES: introductionData.contactPersonDesignation,
      POST_CODE1: parseInt(introductionData.registeredOfficeAddressPincode),
      CITY1: introductionData.registeredOfficeAddressCity,
      DISTRICT_REG: introductionData.district,
      STATE: introductionData.registeredOfficeAddressState,
      BEZEI: introductionData.registeredOfficeAddressStateDescription,
      COUNTRY: introductionData.registeredOfficeAddressCountry,
      LANDX: introductionData.registeredOfficeAddressCountryDescription,
      BUILDING_BLOCK: introductionData.registeredOfficeAddressBuilding,
      STREET: introductionData.registeredOfficeAddressStreet,
      BRIEF_INTRO: introductionData.briefIntroduction || '',
      NAMEOF_FIN_MANGR: introductionData.financeManagerName,
      CNTRY_FIN_MANGR: introductionData.financeManagerPhoneNumberCountryCode,
      PHNOOF_FIN_MANGR: parseInt(introductionData.financeManagerPhoneNumber),
      EMAILOF_FIN_MANGR: introductionData.financeManagerEmail,
      // End of Introduction Data

      // Start of KYV Details
      COUNTRY_TAX_RESID: kyvData.countryOfTaxResidency,
      LANDX1: kyvData.countryOfTaxResidencyLabel,
      DATE_OF_INCORP: kyvData.dateOfIncorporation,
      PANNO: kyvData.PANNumber,
      CAT_TYPE_OF_WORK: kyvData.typeOfWork,
      CIN_NUM: kyvData.CINNumber,
      CIN_STATUS: kyvData.CINStatus,
      GSTNO: kyvData.GSTNumber,
      GST_STATUS: kyvData.GSTStatus,
      MSME_UDAYAM: kyvData.radioOption,
      UDAYAM_NUM: kyvData.udyamNumber,
      MSME_NUM: kyvData.msmeNumber,
      EPF_NUM: kyvData.EPFNumber,
      EPF_STATUS: kyvData.EPFStatus,
      ESIC_NUM: kyvData.ESICNumber,
      DISTRICT: kyvData.ESICRelatedDistrict,
      ESIC_STATUS: kyvData.ESICStatus,
      TAN_NUM: kyvData.TANNumber,
      TAN_STATUS: kyvData.TANStatus,
      TAN_MOBILE: parseInt(kyvData.TANMobileNumber),
      // End of KYV Details

      // Start of Bank Information Data
      BANKL: bankAccountData.swiftCode,
      BANKA: bankAccountData.bankName,
      KOINH: bankAccountData.bankAccountHolderName,
      BANKN: bankAccountData.bankAccountNumber,
      BANK_BRANCH: bankAccountData.branchName,
      BANK_ADDRESS: bankAccountData.bankAddress,
      STRAS: bankAccountData.bankAddressStreet,
      PROVZ: bankAccountData.bankAddressRegion,
      ORT01: bankAccountData.bankAddressCity,
      BKVID: bankAccountData.bankID,
      BKONT: bankAccountData.bankControlKey,
      BANKS: bankAccountData.bankCountryKey,
      //End of Bank Information Data  
    };

    const updatedData = { ...existingData, ...updatedFields };

    const apiData = {
      ...updatedData,
      WERKS: plant,
      BUKRS: companyCode,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    console.log("api data after submission: , apiData");
    console.log("introduction Data is:", introductionData);
    console.log("introduction data fin man phn type", typeof (introductionData.financeManagerPhoneNumber));
    console.log("fin man api data type", typeof (apiData.PHNOOF_FIN_MANGR));


    // fetch(apiUrl, {
    //   method: 'PUT',
    //   headers: {
    //     'Authorization': 'Basic ' + base64Credentials,
    //     'Content-Type': 'application/json',
    //     'X-CSRF-Token': csrfToken,
    //   },
    //   body: JSON.stringify(apiData),
    // })
    //   .then((response) => {
    //     console.log("Response Status is: ", response.status);

    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }

    //     return response.json();
    //   })
    //   .then((responseData) => {
    //     setExistingData(responseData);
    //     setData(responseData);

    //     // Set Introduction Data 
    //     setIntroductionData({
    //       businessRegistration: existingData.businessRegistration || '',
    //       vendorType: existingData.VENDOR_TYPE || '',
    //       organisationType: existingData.ORG_TYPE || '',
    //       organisationName: existingData.VENDOR_COMP_NAME || '',
    //       contactPersonSalutation: existingData.NAMEOFCONT_SAL || '',
    //       contactPersonFirstName: existingData.BU_SORT1_TXT || '',
    //       contactPersonLastName: existingData.BU_SORT2_TXT || '',
    //       contactPersonDesignation: existingData.CONT_PERSON_DES || '',
    //       registeredOfficeAddressPincode: existingData.POST_CODE1 || '',
    //       registeredOfficeAddressCity: existingData.CITY1 || '',
    //       district: existingData.DISTRICT_REG || '',
    //       registeredOfficeAddressState: existingData.STATE || '',
    //       registeredOfficeAddressStateDescription: existingData.BEZEI || '',
    //       registeredOfficeAddressCountry: existingData.COUNTRY || '',
    //       registeredOfficeAddressCountryDescription: existingData.COUNTRY || '',
    //       registeredOfficeAddressBuilding: existingData.BUILDING_BLOCK || '',
    //       registeredOfficeAddressStreet: existingData.STREET || '',
    //       briefIntroduction: existingData.BRIEF_INTRO || '',
    //       financeManagerName: existingData.NAMEOF_FIN_MANGR || '',
    //       financeManagerEmail: existingData.EMAILOF_FIN_MANGR || '',
    //       financeManagerPhoneNumberCountryCode: existingData.CNTRY_FIN_MANGR || '',
    //       financeManagerPhoneNumber: existingData.PHNOOF_FIN_MANGR || '',
    //     });

    //     console.log("Existing Data is:", existingData);
    //     navigate(`/VendorStatus`);
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //     console.error('Error fetching data:', error);
    //     alert('Error fetching data:', error);
    //   });
    const response = await axios.put(apiUrl, apiData, {
      headers: {
        'Authorization': 'Basic ' + base64Credentials,
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      }
    })
      .then((response) => {
        console.log("Response Status is: ", response.status);

        if (response.status < 200 || response.status >= 300) {
          throw new Error('Network response was not ok');
        }

        return response.data; // Directly return the response data.
      })
      .then((responseData) => {
        setExistingData(responseData);
        setData(responseData);

        // Set Introduction Data 
        setIntroductionData({
          businessRegistration: responseData.businessRegistration || '',
          vendorType: responseData.VENDOR_TYPE || '',
          organisationType: responseData.ORG_TYPE || '',
          organisationName: responseData.VENDOR_COMP_NAME || '',
          contactPersonSalutation: responseData.NAMEOFCONT_SAL || '',
          contactPersonFirstName: responseData.BU_SORT1_TXT || '',
          contactPersonLastName: responseData.BU_SORT2_TXT || '',
          contactPersonDesignation: responseData.CONT_PERSON_DES || '',
          registeredOfficeAddressPincode: responseData.POST_CODE1 || '',
          registeredOfficeAddressCity: responseData.CITY1 || '',
          district: responseData.DISTRICT_REG || '',
          registeredOfficeAddressState: responseData.STATE || '',
          registeredOfficeAddressStateDescription: responseData.BEZEI || '',
          registeredOfficeAddressCountry: responseData.COUNTRY || '',
          registeredOfficeAddressCountryDescription: responseData.COUNTRY || '',
          registeredOfficeAddressBuilding: responseData.BUILDING_BLOCK || '',
          registeredOfficeAddressStreet: responseData.STREET || '',
          briefIntroduction: responseData.BRIEF_INTRO || '',
          financeManagerName: responseData.NAMEOF_FIN_MANGR || '',
          financeManagerEmail: responseData.EMAILOF_FIN_MANGR || '',
          financeManagerPhoneNumberCountryCode: responseData.CNTRY_FIN_MANGR || '+91',
          financeManagerPhoneNumber: responseData.PHNOOF_FIN_MANGR || '',
        });

        console.log("Existing Data is:", responseData);
        navigate(`/VendorStatus`);
      })
      .catch((error) => {
        console.log(error.message);
        console.error('Error fetching data:', error);
        alert('Error fetching data:', error);
      });

    console.log("Total Response is: ", response);

  };

  const handleSubmission = () => {
    console.log("Value of isEditMode is: ", isEditMode);
    const button = document.getElementById('submitButton');
    button.disabled = true;
    fetchDataFromApi();
  };

  const saveIntroductionDataToDatabase = async (e) => {
    // setTimeout(() => {
    //   setIsIntroductionDataSaved(true);
    //   setTimeout(() => {
    //     setIsIntroductionDataSaved(false);
    //   }, 5000);
    // }, 1000);

    console.log("Clicked on SAVE Button in Introduction Data");
    console.log("Intro as:", introductionData);
    const updatedFields = {
      BUTXT: introductionData.businessRegistration,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
      VENDOR_TYPE: introductionData.vendorType,
      VENDOR_NO: vendorNumber,
      ORG_TYPE: introductionData.organisationType,
      VENDOR_COMP_NAME: introductionData.organisationName,
      NAMEOFCONT_SAL: introductionData.contactPersonSalutation,
      BU_SORT1_TXT: introductionData.contactPersonFirstName,
      BU_SORT2_TXT: introductionData.contactPersonLastName,
      CONT_PERSON_DES: introductionData.contactPersonDesignation,
      POST_CODE1: parseInt(introductionData.registeredOfficeAddressPincode),
      CITY1: introductionData.registeredOfficeAddressCity,
      DISTRICT_REG: introductionData.district,
      STATE: introductionData.registeredOfficeAddressState, // In SAP DB State is saved as Region
      BEZEI: introductionData.registeredOfficeAddressStateDescription,
      COUNTRY: introductionData.registeredOfficeAddressCountry,
      LANDX: introductionData.registeredOfficeAddressCountryDescription,
      BUILDING_BLOCK: introductionData.registeredOfficeAddressBuilding,
      STREET: introductionData.registeredOfficeAddressStreet,
      BRIEF_INTRO: introductionData.briefIntroduction || '',
      NAMEOF_FIN_MANGR: introductionData.financeManagerName,
      CNTRY_FIN_MANGR: introductionData.financeManagerPhoneNumberCountryCode || '+91',
      PHNOOF_FIN_MANGR: parseInt(introductionData.financeManagerPhoneNumber),
      EMAILOF_FIN_MANGR: introductionData.financeManagerEmail,
    };
    console.log("Existing Data is: 1 : ", existingData);
    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    console.log(apiData);
    console.log("912 CSRF token value is: ",);
    setExistingData(apiData);
    // const api = 'http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor';

    // const res = await axios.get(api, {
    //   headers: {
    //     'Authorization': 'Basic ' + base64Credentials,
    //     // 'Access-Control-Allow-Origin', 'Access-Control-Allow-Headers', 
    //     // and 'Access-Control-Allow-Methods' are handled by the server, not the client
    //     'Content-Type': 'application/json',
    //     'X-CSRF-Token': 'Fetch',
    //   },
    //   params: {
    //     'sap-client': 200,
    //     'AUTO_USER': vendorNo,
    //   },
    // })
    // console.log("res is :", res);

    const apiUrl = `${process.env.REACT_APP_API_PUT_USER}${vendorNo}`;
    console.log("API URL is", apiUrl);
    const response = await axios.put(apiUrl, apiData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
        // 'Access-Control-Allow-Credentials': true,
        'X-CSRF-Token': csrfToken,
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        // 'Access-Control-Allow-Headers': '*',
        // withCredentials: true,
      },
      // withCredentials: true,
    })
      .then((response) => {
        console.log('Data saved successfully:', response);
        setSaveData({ ...saveData, isIntroductionData: true });
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });

  };

  const hasErrorMessages = () => {
    return Object.values(errorMessages).some((errorMessage) => errorMessage !== '');
  };

  const saveKYVDataToDatabase = async (e) => {
    if (hasErrorMessages()) {
      alert("Cannot Save KYV Data. Please Enter Valid Data.");
      return;
    }

    console.log("KYV Data: ", kyvData);
    const updatedFields = {
      COUNTRY_TAX_RESID: kyvData.countryOfTaxResidency,
      LANDX1: kyvData.countryOfTaxResidencyLabel,
      DATE_OF_INCORP: kyvData.dateOfIncorporation,
      PANNO: kyvData.PANNumber,
      CAT_TYPE_OF_WORK: kyvData.typeOfWork,
      CIN_NUM: kyvData.CINNumber,
      CIN_STATUS: kyvData.CINStatus,
      GSTNO: kyvData.GSTNumber,
      GST_STATUS: kyvData.GSTStatus,
      MSME_UDAYAM: kyvData.radioOption,
      UDAYAM_NUM: kyvData.udyamNumber,
      MSME_NUM: kyvData.msmeNumber,
      EPF_NUM: kyvData.EPFNumber,
      EPF_STATUS: kyvData.EPFStatus,
      ESIC_NUM: kyvData.ESICNumber,
      ESIC_STATUS: kyvData.ESICStatus,
      DISTRICT: kyvData.ESICRelatedDistrict,
      TAN_NUM: kyvData.TANNumber,
      TAN_STATUS: kyvData.TANStatus,
      TAN_MOBILE: parseInt(kyvData.TANMobileNumber),
    }
    console.log("Existing Data is: 2 : ", existingData);
    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    console.log("KYV Data1", apiData);
    setExistingData(apiData);
    setTimeout(() => {
      setIsKYVDataSaved(true);
      setTimeout(() => {
        setIsKYVDataSaved(false);
      }, 5000);
    }, 1000);
    const apiUrl = `${process.env.REACT_APP_API_PUT_USER}${vendorNo}`;
    // fetch(apiUrl, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Basic ' + base64Credentials,
    //     'X-CSRF-Token': csrfToken,
    //   },
    //   body: JSON.stringify(apiData),
    //   withCredentials: true,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('KYV Data saved successfully:', data);
    //     setSaveData({ ...saveData, isKYVData: true });
    //   })
    //   .catch((error) => {
    //     console.error('Error saving data:', error);
    //   });
    const response = await axios.put(apiUrl, apiData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
        'X-CSRF-Token': csrfToken,
      },
      // withCredentials: true,
    })
      .then((response) => {
        console.log('KYV Data saved successfully:', response.data);
        setSaveData({ ...saveData, isKYVData: true });
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
    console.log("Updated KYV details are: ", response);

    console.log("CINReg", kyvData.isCINNotRegisteredChecked, "CINApp", kyvData.isCINNotApplicableChecked);
    console.log(kyvData);
    console.log("KYV Data is : ", apiData);
  };

  const saveBankInformationDataToDatabase = async (e) => {
    setTimeout(() => {
      setIsBankDataSaved(true);
      setTimeout(() => {
        setIsBankDataSaved(false);
      }, 5000);
    }, 1000);

    const updatedFields = {
      BANKL: bankAccountData.swiftCode,
      BANKA: bankAccountData.bankName,
      KOINH: bankAccountData.bankAccountHolderName,
      BANKN: bankAccountData.bankAccountNumber,
      BANK_BRANCH: bankAccountData.branchName,
      BANK_ADDRESS: bankAccountData.bankAddress,
      STRAS: bankAccountData.bankAddressStreet,
      PROVZ: bankAccountData.bankAddressRegion,
      ORT01: bankAccountData.bankAddressCity,
      BKVID: bankAccountData.bankID,
      BKONT: bankAccountData.bankControlKey,
      BANKS: bankAccountData.bankCountryKey,
    }
    console.log("Existing Data is: 4 : ", existingData);

    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    setExistingData(apiData);
    const apiUrl = `${process.env.REACT_APP_API_PUT_USER}${vendorNo}`;
    // fetch(apiUrl, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Basic ' + base64Credentials,
    //     'X-CSRF-Token': csrfToken,
    //   },
    //   body: JSON.stringify(apiData),
    //   withCredentials: true,
    // })
    //   .then((response) => { response.json(); console.log("Response Status is: ", response.status); })
    //   .then((data) => {
    //     setSaveData({ ...saveData, isBankData: true });
    //     console.log('Data saved successfully:', data);
    //   })
    //   .catch((error) => {
    //     console.error('Error saving data:', error);
    //   });
    const response = axios.put(apiUrl, apiData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
        'X-CSRF-Token': csrfToken,
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log("Response Status is: ", response.status);
        return response.data; // Ensure the data is returned for further processing.
      })
      .then((data) => {
        setSaveData({ ...saveData, isBankData: true });
        console.log('Data saved successfully:', data);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });

    console.log("Bank Response is: ", response);

  };

  const displayLabels = {
    '': 'Select Option',
    '01': 'Registered',
    '02': 'Non-Registered',
    '03': 'ITC',
  };

  const handleIntroductionInputChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      if (name === "registeredOfficeAddressCity" || name === "district") {
        if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
          setIntroductionData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      else {
        setIntroductionData(
          { ...introductionData, [name]: value });
      }
    }
  }

  const validateEmail = (email) => {
    console.log('Validating email:', email);
    const isValid = email.includes('@');
    console.log('Is valid email:', isValid, "email len is: ", email.length);
    setIsValidEmail(isValid);
    if (email.length === 0) {
      const isVal = (email.length === 0);
      setIsValidEmail(isVal);
    }
  };

  const handleEmailBlur = (event) => {
    const { name, value } = event.target;

    if (name === 'financeManagerEmail') {
      validateEmail(value);
    }
  };

  const handlePinCodeChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      const newPinCode = value;
      const numericPinCode = newPinCode.replace(/[^0-9]/g, '');
      setIntroductionData(
        { ...introductionData, [name]: numericPinCode });
    }
  }

  const handlePhoneInputChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      console.log("Name is : ", name, "type of name is: ", typeof (name), "and Value is : ", value);
      const newPhoneNumber = value;
      if (name === "financeManagerPhoneNumber") {
        const numericPhoneNumber = newPhoneNumber.replace(/[^0-9]/g, '');
        setIntroductionData(
          { ...introductionData, [name]: numericPhoneNumber });
      }

      else if (name === "TANMobileNumber") {
        const numericPhoneNumber = newPhoneNumber.replace(/[^0-9]/g, '');
        setKYVData(
          { ...kyvData, [name]: numericPhoneNumber });
      }
    }
  }

  const formatDateToDMY = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleKYVInputChange = (event) => {
    if (event.target && event.target.name) {
      const { name, value, type, checked } = event.target;
      if (name === "PANNumber") {
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
        if (panRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: '',
          }));
        }

        else if (formattedValue.length === 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: 'Invalid PAN format. Please enter a valid PAN. Ex: ABCTY1234D',
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: 'Not as PAN Format',
          }));
        }
      }

      else if (name === "CINNumber") {
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        console.log(formattedValue + " not condition");
        if (!kyvData.dateOfIncorporation) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: 'Please fill in the date of incorporation first.',
          }));
        } else {
          // Check for CINNumber format (21 characters, alphanumeric)
          const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
          if (cinRegex.test(formattedValue)) {
            // Extract year from dateOfIncorporation and compare with CINNumber
            const yearFromCIN = formattedValue.substring(8, 12);
            const yearFromDateOfIncorporation = kyvData.dateOfIncorporation.substring(0, 4);
            console.log("CIN year is: ", yearFromCIN, "Date of Incop year is: ", yearFromDateOfIncorporation);

            if (yearFromCIN === yearFromDateOfIncorporation) {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
                CINStatus: "Filled"
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                CINError: '',
              }));
            } else {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                CINError: 'Year in CINNumber does not match the year in dateOfIncorporation.',
              }));
            }
          } else if (formattedValue.length === 21) {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              CINError: 'Invalid CINNumber format. Please use the standard format, eg: L12345KA2023PLC123456'
            }));
          } else {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              CINError: 'Not as CIN Number Format. Ex: L12345KA2023PLC123456'
            }));
          }
        }
      }

      else if (name === "GSTNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check if Registered Address Office State Filled/ not
        if (!introductionData.registeredOfficeAddressState) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Kindly fill State in Introduction Data Tab, then fill GST Number.',
          }));
        }

        else if (!kyvData.PANNumber) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Kindly fill PAN Number first, then fill GST.',
          }));
        }

        else if (kyvData.PANNumber.length !== 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Please fill PAN Completely, then fill GST.',
          }));
        }

        else if (kyvData.PANNumber.length === 10 && errorMessages.PANError !== "") {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Please fill PAN Number Correctly.',
          }));
        }

        // else {
        //   // Check for PANNumber format (5 alphabets, 4 numbers, 1 alphabet)
        //   const panRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z][0-9A-Z]{1})/;
        //   const extractedPAN = formattedValue.substring(2, 12);
        //   const extractedStateCode = formattedValue.substring(0, 2);

        //   if (panRegex.test(formattedValue)) {
        //     // Extract the PAN number from the GST, Check if extracted PAN matches the provided PAN
        //     if (extractedPAN === kyvData.PANNumber && extractedStateCode === introductionData.registeredOfficeAddressState) {
        //       setKYVData((prevState) => ({
        //         ...prevState,
        //         [name]: formattedValue,
        //         GSTStatus: "Filled"
        //       }));
        //       setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         GSTError: '',
        //       }));
        //     }
        //   } else if (formattedValue.length === 15) {
        //     const fourteenthCharacter = formattedValue.charAt(13);

        //     if (fourteenthCharacter === 'Z') {
        //       // The 14th character is 'Z', so the GST number is valid.
        //       setKYVData((prevState) => ({
        //         ...prevState,
        //         [name]: formattedValue,
        //       }));
        //       setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         GSTError: '',
        //       }));
        //     }
        //     else if (extractedStateCode !== introductionData.registeredOfficeAddressState) {
        //       setKYVData((prevState) => ({
        //         ...prevState,
        //         [name]: formattedValue,
        //       }));
        //       setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         GSTError: 'Invalid State Code in GST. Registered Office State Code is not same as provided one.',
        //       }));
        //     }
        //     else if (extractedPAN !== kyvData.PANNumber) {
        //       setKYVData((prevState) => ({
        //         ...prevState,
        //         [name]: formattedValue,
        //       }));
        //       setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         GSTError: 'Invalid PAN in GST. PAN in GST should match the provided PAN number.',
        //       }));
        //     }
        //     else {
        //       // The 14th character is not 'Z', so it's an invalid GST number.
        //       setKYVData((prevState) => ({
        //         ...prevState,
        //         [name]: formattedValue,
        //       }));
        //       setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         GSTError: 'Invalid GST format. The 14th character should be Z.',
        //       }));
        //     }
        //   } else {
        //     setKYVData((prevState) => ({
        //       ...prevState,
        //       [name]: formattedValue,
        //     }));
        //     setErrorMessages((prevErrors) => ({
        //       ...prevErrors,
        //       GSTError: 'Not as GST Format',
        //     }));
        //   }
        // }
        const panRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z][0-9A-Z]{1})$/;

        if (formattedValue.length === 15) {
          const extractedPAN = formattedValue.substring(2, 12);
          const extractedStateCode = formattedValue.substring(0, 2);
          const fourteenthCharacter = formattedValue.charAt(13);

          if (!panRegex.test(formattedValue)) {
            // Invalid GST format
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Invalid GST format.',
            }));
          } else if (fourteenthCharacter !== 'Z') {
            // 14th character should be Z
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Invalid GST format. The 14th character should be Z.',
            }));
          } else if (extractedStateCode !== introductionData.registeredOfficeAddressState) {
            // State code mismatch
            console.log("introductionData.registeredOfficeAddressState is:", introductionData.registeredOfficeAddressState);
            console.log("extractedStateCode is: ", extractedStateCode);
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Invalid State Code in GST. Registered Office State Code is not the same as provided one.',
            }));
          } else if (extractedPAN !== kyvData.PANNumber) {
            // PAN mismatch
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Invalid PAN in GST. PAN in GST should match the provided PAN number.',
            }));
          } else {
            // GST is valid
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
              GSTStatus: "Filled",
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: '',
            }));
          }
        } else {
          // Invalid GST length
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Invalid GST length. GST number should be 15 characters long.',
          }));
        }

      }

      else if (name === "EPFNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for udyamNumber format (22 characters, alphanumeric)
        const cinRegex = /^[A-Z]{5}[0-9]{17}/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            EPFStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));
        } else if (formattedValue.length === 22) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: 'Invalid EPF Number format. Please enter a valid EPF Number. Ex: CCROC00112271234455662'
          }));
        } else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: 'Not as EPF Number Format. Ex: CCROC00112271234455662'
          }));
        }
      }

      else if (name === "ESICNumber") {
        console.log(name + " " + value);
        let formattedValue = value.replace(/[^0-9]/g, '');
        //let formattedValue = value.replace(/\D/g, '');
        const cinRegex = /^\d{17}$/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            ESICStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));
        } else if (formattedValue.length === 17) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Invalid ESIC Number format. Please enter a valid EPF Number. Ex: 00112271234455662'
          }));
        }
        else if (formattedValue.length > 17) {
          formattedValue = formattedValue.slice(0, 17); // Trim to 17 digits
        } else if (!/^\d+$/.test(value)) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Only numeric characters are allowed.'
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Not as ESIC Number Format. Ex: 00112271234455662'
          }));
        }
      }

      else if (name === "TANNumber") {
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for TANNumber format (4 alphabets, 5 numbers, 1 alphabet)
        const panRegex = /[A-Z]{4}\d{5}[A-Z]$/;
        if (panRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            TANStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        else if (formattedValue.length === 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: 'Invalid TAN format. Please enter a valid PAN. Ex: ABCT01234D'
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: 'Not as TAN Format'
          }));
        }
      }

      else if (type === "date") {
        console.log("Entered Date", value);
        const formattedDate = formatDateToDMY(value);
        console.log(formattedDate);
        setKYVData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      else if (type === "checkbox") {
        if ((name === 'isCINNotApplicableChecked' || name === 'isCINNotRegisteredChecked') && checked) {
          if ((name === 'isCINNotApplicableChecked') && kyvData.isCINNotApplicableChecked === false) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === false) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === true)) {
              console.log("Entered IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable and Not Registered"
              }));
              console.log("CIN Status is: ", kyvData.CINStatus);
            }

            else {
              console.log("Entered IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isCINNotRegisteredChecked') && kyvData.isCINNotRegisteredChecked === false) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === false) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: '',
          }));

        }

        else if ((name === 'isCINNotApplicableChecked' || name === 'isCINNotRegisteredChecked') && checked === false) {
          if ((name === 'isCINNotApplicableChecked') && kyvData.isCINNotApplicableChecked === true) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === true) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }

            else {
              console.log("Entered ELSE IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isCINNotRegisteredChecked') && kyvData.isCINNotRegisteredChecked === true) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === true) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: '',
          }));

        }

        else if ((name === 'isGSTNotApplicableChecked' || name === 'isGSTNotRegisteredChecked') && checked) {
          if ((name === 'isGSTNotApplicableChecked') && kyvData.isGSTNotApplicableChecked === false) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === false) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === true)) {
              console.log("Entered IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }

            else {
              console.log("Entered IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          else if ((name === 'isGSTNotRegisteredChecked') && kyvData.isGSTNotRegisteredChecked === false) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === false) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: '',
          }));

        }

        else if ((name === 'isGSTNotApplicableChecked' || name === 'isGSTNotRegisteredChecked') && checked === false) {
          if ((name === 'isGSTNotApplicableChecked') && kyvData.isGSTNotApplicableChecked === true) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === true) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }

            else {
              console.log("Entered ELSE IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isGSTNotRegisteredChecked') && kyvData.isGSTNotRegisteredChecked === true) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === true) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Filled"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: '',
          }));

        }

        else if ((name === 'isEPFNotApplicableChecked' || name === 'isEPFNotRegisteredChecked') && checked) {
          if ((name === 'isEPFNotApplicableChecked') && kyvData.isEPFNotApplicableChecked === false) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === false) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === true)) {
              console.log("Entered IF If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }

            else {
              console.log("Entered IF If->else EPF");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          else if ((name === 'isEPFNotRegisteredChecked') && kyvData.isEPFNotRegisteredChecked === false) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === false) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));

        }

        else if ((name === 'isEPFNotApplicableChecked' || name === 'isEPFNotRegisteredChecked') && checked === false) {
          if ((name === 'isEPFNotApplicableChecked') && kyvData.isEPFNotApplicableChecked === true) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === true) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }

            else {
              console.log("Entered ELSE IF If->else EPF");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Filled"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          else if ((name === 'isEPFNotRegisteredChecked') && kyvData.isEPFNotRegisteredChecked === true) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === true) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable"
              }));
              console.log(kyvData.EPFStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Filled"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));

        }

        else if ((name === 'isESICNotApplicableChecked' || name === 'isESICNotRegisteredChecked') && checked) {
          if ((name === 'isESICNotApplicableChecked') && kyvData.isESICNotApplicableChecked === false) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === false) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === true)) {
              console.log("Entered IF If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable and Not Registered"
              }));
              console.log("ESIC Status is: ", kyvData.ESICStatus);
            }

            else {
              console.log("Entered IF If->else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          else if ((name === 'isESICNotRegisteredChecked') && kyvData.isESICNotRegisteredChecked === false) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === false) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }
            else {
              console.log("Entered IF Else if-> else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));

        }

        else if ((name === 'isESICNotApplicableChecked' || name === 'isESICNotRegisteredChecked') && checked === false) {
          if ((name === 'isESICNotApplicableChecked') && kyvData.isESICNotApplicableChecked === true) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === true) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }

            else {
              console.log("Entered ELSE IF If->else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Filled"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          else if ((name === 'isESICNotRegisteredChecked') && kyvData.isESICNotRegisteredChecked === true) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === true) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable"
              }));
              console.log(kyvData.ESICStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Filled"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));

        }

        else if (name === 'isTANNotApplicableChecked' && checked) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked,
            TANNumber: '',
            TANMobileNumber: '',
            TANStatus: 'Not Applicable',
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        else if (name === 'isTANNotApplicableChecked' && checked === false) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked,
            TANStatus: 'Filled',
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked,
          }));
        }
      }

      else if (type === "radio") {
        console.log(name, value, "radio");
        setKYVData((prevState) => ({
          ...prevState,
          [name]: checked ? value : '',
        }));
      }

      else {
        console.log(name, value);
        setKYVData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

    }

    else {
      setKYVData((prevState) => ({
        ...prevState,
        financeManagerPhoneNumber: event,
      }));
    }
  };

  const formatDateForDisplay = (date) => {
    const [yyyy, mm, dd] = date.split('-');
    return `${dd}-${mm}-${yyyy}`;
  };

  const fetchBankDetails = async (ifscCode) => {
    try {
      const response = await fetch(`https://bank-apis.justinclicks.com/API/V1/IFSC/${ifscCode}/`, {
        method: 'GET',
      });
      console.log("Fetching IFSC API");
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setBankAccountData((prevState) => ({
          ...prevState,
          bankName: data.BANK,
          branchName: data.BRANCH,
          bankAddressRegion: data.DISTRICT,
          bankAddressStreet: data.CENTRE,
          bankAddressCity: data.CITY,
        }));
      } else {
        console.error("Error fetching bank details from API");
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const [bankAccountNumberError, setBankAccountNumberError] = useState('');

  const handleBankAccountInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "swiftCode") {
      setBankAccountData({ ...bankAccountData, swiftCode: value.toUpperCase() });
      if (value.length === 11) {
        setBankAccountData({ ...bankAccountData, swiftCode: value.toUpperCase() });

        fetchBankDetails(value);
      }
      else {
        setBankAccountData((prevState) => ({
          ...prevState,
          bankName: '',
          branchName: '',
          bankAddressRegion: '',
          bankAddressStreet: '',
          bankAddressCity: '',
        }));
      }
    }
    else if (name === "bankAccountNumber") {
      const numericValue = parseInt(value);
      if (!isNaN(numericValue) && value.length >= 12 && value.length <= 18) {
        setBankAccountData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
        setBankAccountNumberError('');
      }

      else if (!isNaN(numericValue) && value.length > 18) {
        setBankAccountNumberError('');
      }

      else {
        setBankAccountData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
        setBankAccountNumberError('Account number must be between 12 and 18 digits.');
      }
    }
    else {
      setBankAccountData({ ...bankAccountData, [name]: value });
    }
  };

  const handleOrganisationTypeChange = (event) => {
    const { name, value } = event.target;
    setIntroductionData({ ...introductionData, [name]: value });
  };

  const handleVendorTypeChange = (event) => {
    const { name, value } = event.target;
    setIntroductionData({ ...introductionData, [name]: value });
  };

  const handleNext = () => {
    if (currentStep === 1) {
      if (true) {
        setCurrentStep(currentStep + 1);
        steps[currentStep - 1].isCompleted = true;
        setCompletedSteps([...completedSteps, currentStep]);
        markStepAsCompleted(currentStep);
      } else {
        // Stay on the current step or show an error message
      }
    }
    else if (currentStep === 2 || currentStep === 3) {
      if (true) {
        setCurrentStep(currentStep + 1);
        steps[currentStep - 1].isCompleted = true;
        setCompletedSteps([...completedSteps, currentStep]);
        markStepAsCompleted(currentStep);
      }

      else {
        console.log("Cannot redirect to next Page");
      }
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    console.log(introductionData);
    console.log(kyvData);
    console.log(bankAccountData);
  };

  const handleReset = async () => {
    if (currentStep === 1) {
      setTimeout(() => {
        setIsIntroductionDataResetted(true);
        setTimeout(() => {
          setIsIntroductionDataResetted(false);
        }, 5000);
      }, 1000);
      const mandatoryFields = ['businessRegistration', 'vendorPrimaryEmail', 'vendorPrimaryMobile', 'organisationName', 'vendorPrimaryMobileCountryCode'];
      const preservedValues = Object.fromEntries(
        Object.entries(introductionData)
          .filter(([fieldName]) => mandatoryFields.includes(fieldName))
      );
      const business = introductionData.businessRegistration;
      const email = introductionData.vendorPrimaryEmail;
      const mobile = parseInt(introductionData.vendorPrimaryMobile);
      const org = introductionData.organisationName;
      const mobileCode = introductionData.vendorPrimaryMobileCountryCode;
      console.log("Before Data: ", introductionData);
      setIntroductionData((prevData) => ({
        ...prevData,
        ...initialIntroductionData,
        ...preservedValues,
        businessRegistration: business,
        vendorPrimaryEmail: email,
        vendorPrimaryMobile: mobile,
        organisationName: org,
        vendorPrimaryMobileCountryCode: mobileCode,
      }));

      const updatedFields = {
        ...existingData,
        BUTXT: introductionData.businessRegistration,
        VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
        TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
        VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
        VENDOR_TYPE: initialIntroductionData.vendorType,
        VENDOR_NO: vendorNumber,
        ORG_TYPE: initialIntroductionData.organisationType,
        VENDOR_COMP_NAME: introductionData.organisationName,
        NAMEOFCONT_SAL: initialIntroductionData.contactPersonSalutation,
        BU_SORT1_TXT: initialIntroductionData.contactPersonFirstName,
        BU_SORT2_TXT: initialIntroductionData.contactPersonLastName,
        CONT_PERSON_DES: initialIntroductionData.contactPersonDesignation,
        POST_CODE1: parseInt(initialIntroductionData.registeredOfficeAddressPincode),
        CITY1: initialIntroductionData.registeredOfficeAddressCity,
        DISTRICT_REG: initialIntroductionData.district,
        STATE: initialIntroductionData.registeredOfficeAddressState,
        BEZEI: initialIntroductionData.registeredOfficeAddressStateDescription,
        COUNTRY: initialIntroductionData.registeredOfficeAddressCountry,
        LANDX: initialIntroductionData.registeredOfficeAddressCountryDescription,
        BUILDING_BLOCK: initialIntroductionData.registeredOfficeAddressBuilding,
        STREET: initialIntroductionData.registeredOfficeAddressStreet,
        BRIEF_INTRO: initialIntroductionData.briefIntroduction,
        NAMEOF_FIN_MANGR: initialIntroductionData.financeManagerName,
        CNTRY_FIN_MANGR: initialIntroductionData.financeManagerPhoneNumberCountryCode || '+91',
        PHNOOF_FIN_MANGR: parseInt(initialIntroductionData.financeManagerPhoneNumber),
        EMAILOF_FIN_MANGR: initialIntroductionData.financeManagerEmail,
      };
      setSelectedAPICountryValue('');
      setSelectedAPIStateValue('');

      const updatedData = { ...existingData, ...updatedFields };
      const resetApiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&AUTO_USER=${vendorNo}`;
      console.log("Updated Data is: ", updatedData);

      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(updatedData),
        withCredentials: true,
      });
      console.log("After Data: ", introductionData);


    } else if (currentStep === 2) {

      setTimeout(() => {
        setIsKYVDataResetted(true);
        setErrorMessages(initialKYVErrorMessages);
        setTimeout(() => {
          setIsKYVDataResetted(false);
        }, 5000);
      }, 1000);
      setKYVData(initialKYVData);
      setSelectedAPICountryOfTaxResidencyValue('');
      const updatedData = {
        ...existingData,
        LANDX1: '',

      }
      const resetApiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&AUTO_USER=${vendorNo}`;
      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(updatedData),
        withCredentials: true,
      });
      console.log('Fields reset successfully in KYV Screen');
      console.log("Existing Data is: ", existingData);

    } else if (currentStep === 3) {

      setTimeout(() => {
        setIsBankDataResetted(true);
        setTimeout(() => {
          setIsBankDataResetted(false);
        }, 5000);
      }, 1000);

      setBankAccountData(initialbankAccountData);
      const updatedData = {
        ...existingData,
        BANKL: initialbankAccountData.swiftCode,
        BANKA: bankAccountData.bankName,
        KOINH: bankAccountData.bankAccountHolderName,
        BANKN: bankAccountData.bankAccountNumber,
        BANK_BRANCH: bankAccountData.branchName,
        BANK_ADDRESS: bankAccountData.bankAddress,
        STRAS: bankAccountData.bankAddressStreet,
        PROVZ: bankAccountData.bankAddressRegion,
        ORT01: bankAccountData.bankAddressCity,
        BKVID: bankAccountData.bankID,
        BKONT: bankAccountData.bankControlKey,
        BANKS: bankAccountData.bankCountryKey,

      }
      const resetApiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&AUTO_USER=${vendorNo}`;
      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(updatedData),
        withCredentials: true,
      });
      console.log("Existing Data is : ", existingData);
      console.log('Fields reset successfully in Bank Screen');
    }
    steps.forEach((step) => {
      step.isCompleted = false;
    });
  };

  return (
    <div>
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}

      <div className="container" style={{ padding: '0px', marginTop: '5px' }}>
        <div className='d-flex' style={{ margin: '0px' }}>
          <div id="backgroundImageContainer" style={{ width: '30%' }}>
            <div className="card card-header-registration-bg">
              <div className="d-flex flex-row justify-content-center text-center">
                <div className="card-header-registration">
                  <h4>Vendor Onboarding</h4>
                </div>
              </div>
            </div>
          </div>

          <div id="stepperContainer" style={{ width: '70%' }}>
            <Box>
              <Stepper activeStep={currentStep - 1} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step.id} onClick={() => handleStepperClick(step.id, true)} className='stepper-number-click-registration'>
                    <StepLabel
                      icon={
                        <div
                          style={{
                            backgroundColor:
                              currentStep === step.id ? 'pink' :
                                '#6495ED',
                            borderRadius: '50%',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ color: 'white', fontSize: '15px', margin: '0px', padding: '0px' }}>{step.icon}</span>
                        </div>
                      }
                      style={{ margin: '0px', padding: '0px' }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>

        </div>

        <div className="card-body-registration">
          <form onSubmit={handleSubmit}>
            {currentStep === 1 && (

              <div className="table1-registration">
                <div className='row margin-settings'>
                  {/* Business Registration */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="businessRegistration">
                      Which of our Business would you like to register with
                    </label>
                  </div>
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="businessRegistration"
                      name="businessRegistration"
                      value={introductionData.businessRegistration}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                      disabled={true}
                      required
                    />) : (
                      <span className='text-left-registration view-mode-stylings'>{introductionData.businessRegistration}</span>
                    )}
                  </div>

                  {/* Vendor Type */}

                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="vendorType">
                      Vendor Type
                    </label>
                  </div>

                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className="custom-select-wrapper">
                      {isEditMode ? (<select
                        id="vendorType"
                        name="vendorType"
                        className="form-control select-input-registration"
                        onChange={handleVendorTypeChange}
                        value={introductionData.vendorType}
                      >
                        <option value="">Select Option</option>
                        <option value="01">Registered</option>
                        <option value="01">Non-Registered</option>
                        <option value="02">ITC</option>
                      </select>) : (
                        <span className='view-mode-stylings'>{displayLabels[introductionData.vendorType]}</span>
                      )}
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Name of the organisation */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="organisationName">
                      Name of your Organisation
                    </label>
                  </div>
                  {/* Select of Name of the organisation Type */}
                  <div className='col-3 col-md-1 custom-select-container text-left-registration'>
                    <div className="custom-select-wrapper">
                      {isEditMode ? (<select
                        id="organisationType" name="organisationType"
                        className="form-control select-input-registration"
                        onChange={handleOrganisationTypeChange} value={introductionData.organisationType}
                      >
                        <option value="">Select</option>
                        <option value="Pvt.Ltd">Pvt Ltd</option>
                      </select>) : (
                        <span className='view-mode-stylings'>{introductionData.organisationType}</span>
                      )}
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                  {/* Organisation Name */}
                  <div className='col-4 col-md-3 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="organisationName"
                      name="organisationName"
                      value={introductionData.organisationName}
                      onChange={handleIntroductionInputChange}
                      maxLength="30"
                      disabled={true}
                      required
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.organisationName}</span>
                    )}
                  </div>

                  {/* Vendor Primary Email Address */}
                  <div className="col-5 col-md-2 form-label-styling-registration mt-2">
                    {/* Label of Vendor Primary Email Address */}
                    <label htmlFor="vendorPrimaryEmail">
                      Vendor Primary Email
                    </label>
                  </div>
                  {/* Vendor Primary Email Address Input */}
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="email"
                      className="form-control select-input-registration"
                      id="vendorPrimaryEmail"
                      name="vendorPrimaryEmail"
                      value={introductionData.vendorPrimaryEmail}
                      onChange={handleIntroductionInputChange}
                      maxLength="50"
                      disabled={true}
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.vendorPrimaryEmail}</span>
                    )}
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Vendor Primary Mobile */}
                  {/* Label of Vendor Primary Mobile */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="vendorPrimaryMobileCountryCode">
                      Vendor Primary Mobile
                    </label>
                  </div>

                  {/* <div className='col-1 custom-select-container text-left-registration'>
                    
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="vendorPrimaryMobileCountryCode" onChange={handleSelectCountryCodeChange} value={selectedPrimaryVendorCountryCode}
                        className='form-control select-input-registration custom-select-monospace' disabled={true}>
                        <option value="">Select an option</option>
                        {[...distinctCountryCodeMap].map(([key, value], index) => (
                          <option key={index} value={`+${value}`}>
                            {`${key}`.padEnd(4, ' ')} - {`+${value}`}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.vendorPrimaryMobileCountryCode} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div> */}

                  <div className='col-1 custom-select-container text-left-registration'>
                    {/* Country Code */}
                    <div className='custom-select-wrapper'>
                      {/* <span className='view-mode-stylings'> {introductionData.vendorPrimaryMobileCountryCode} </span> */}
                      {isEditMode ? (<input
                        type="text"
                        id="vendorPrimaryMobileCountryCode"
                        className='form-control select-input-registration'
                        name="vendorPrimaryMobile"
                        value={introductionData.vendorPrimaryMobileCountryCode}
                        // onChange={handleIntroductionInputChange}
                        placeholder="Country Code"
                        // minLength={10}
                        // maxLength={10}
                        disabled={true}
                      />
                      )
                        : (
                          <span className='view-mode-stylings'>{introductionData.vendorPrimaryMobileCountryCode}</span>
                        )}
                    </div>
                  </div>

                  <div className="col-6 col-md-3 text-left-registration">
                    {/* Label of Vendor Primary Mobile */}
                    {isEditMode ? (<input
                      type="number"
                      id="vendorPrimaryMobile"
                      className='form-control select-input-registration'
                      name="vendorPrimaryMobile"
                      value={introductionData.vendorPrimaryMobile}
                      onChange={handleIntroductionInputChange}
                      placeholder="Vendor Phone Number"
                      minLength={10}
                      maxLength={10}
                      disabled={true}
                    />
                    )
                      : (
                        <span className='view-mode-stylings'>{introductionData.vendorPrimaryMobile}</span>
                      )}
                  </div>

                </div>

                <div className='d-flex'>
                  <div className='col-6 col-md-6'>
                    <div className='row margin-settings'>
                      {/* Contact Person Label */}
                      {/* Label of Vendor Primary Mobile */}
                      <div className='col-5 col-md-4 form-label-styling-registration'>
                        <label htmlFor="contactPersonSalutation">
                          Contact Person Name
                        </label>
                      </div>

                      <div className="col-1 col-md-2 custom-select-container text-left-registration">
                        <div className='custom-select-wrapper'>
                          {isEditMode ? (<select
                            id="contactPersonSalutation"
                            name="contactPersonSalutation"
                            className="form-control select-input-registration"
                            onChange={handleOrganisationTypeChange}
                            value={introductionData.contactPersonSalutation}
                          >
                            <option value=""></option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Miss.">Miss</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Ms.">Ms.</option>
                          </select>
                          ) : (
                            <span className='view-mode-stylings'>{introductionData.contactPersonSalutation}</span>
                          )
                          }
                          {
                            isEditMode ? (
                              <i className="fas fa-circle-chevron-down custom-caret"></i>
                            ) : (
                              <span></span>
                            )
                          }
                        </div>
                      </div>

                      <div className='col-3 col-md-3 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonFirstName"
                          name="contactPersonFirstName"
                          placeholder='First Name'
                          value={introductionData.contactPersonFirstName}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                        />
                        ) : (
                          <span className='view-mode-stylings'>{introductionData.contactPersonFirstName}{" "}{introductionData.contactPersonLastName}</span>
                        )
                        }
                      </div>

                      <div className='col-3 col-md-3 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonLastName"
                          name="contactPersonLastName"
                          placeholder='Last Name'
                          value={introductionData.contactPersonLastName}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                        />
                        ) : (<span></span>)
                        }
                      </div>
                    </div>
                  </div>

                  <div className='col-6 col-md-6'>
                    <div className='row margin-settings'>
                      {/* Contact Person Designation Label */}
                      <div className='col-5 col-md-4 form-label-styling-registration'>
                        <label htmlFor="contactPersonDesignation">
                          Contact Person Designation
                        </label>
                      </div>

                      <div className='col-6 col-md-8 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonDesignation"
                          name="contactPersonDesignation"
                          value={introductionData.contactPersonDesignation}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                          required
                        />
                        ) : (
                          <span className='view-mode-stylings'>{introductionData.contactPersonDesignation}</span>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                {/*Address Details */}
                <div className="sub-headings margin-settings">
                  <h6>Address Details</h6>
                </div>
                <div className='row margin-settings'>
                  {/* Registered Office Address Building Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressBuilding">
                      Building
                    </label>
                  </div>

                  {/* Registered Office Address Building Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressBuilding"
                      name="registeredOfficeAddressBuilding"
                      value={introductionData.registeredOfficeAddressBuilding}
                      onChange={handleIntroductionInputChange}
                      maxLength="100"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressBuilding}</span>
                    )}
                  </div>

                  {/* Registered Office Address Street Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressStreet">
                      Street
                    </label>
                  </div>

                  {/* Registered Office Address Street Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressStreet"
                      name="registeredOfficeAddressStreet"
                      value={introductionData.registeredOfficeAddressStreet}
                      onChange={handleIntroductionInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressStreet}</span>
                    )}
                  </div>


                </div>

                <div className='row margin-settings'>

                  {/* Registered Office Address City Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressCity">
                      City
                    </label>
                  </div>

                  {/* Registered Office Address City Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCity"
                      name="registeredOfficeAddressCity"
                      value={introductionData.registeredOfficeAddressCity}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressCity}</span>
                    )
                    }
                  </div>

                  {/*District */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    {/* District Label */}
                    <label htmlFor="district">
                      District
                    </label>
                  </div>
                  {/*District Input */}
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="district"
                      name="district"
                      value={introductionData.district}
                      onChange={handleIntroductionInputChange}
                      maxLength="50"
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.district}</span>
                    )
                    }
                  </div>
                </div>

                <div className='row margin-settings'>

                  {/* Registered Office Address Country Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressCountry">
                      Country
                    </label>
                  </div>

                  {/* Registered Office Address Country Input */}
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="registeredOfficeAddressCountry" onChange={handleSelectCountryChange} value={selectedAPICountryValue}
                        className='form-control select-input-registration'>
                        <option value="">Select an option</option>
                        {[...distinctCountryMap].map(([key, value], index) => (
                          <option key={index} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.registeredOfficeAddressCountryDescription} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                  {/* Registered Office Address State Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressState">
                      State
                    </label>
                  </div>

                  {/* Registered Office Address State Input */}
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="registeredOfficeAddressState" onChange={handleSelectStateChange} value={selectedAPIStateValue}
                        className='form-control select-input-registration'
                      >
                        <option value="">Select an option</option>
                        {countryData.map((item, index) => (
                          <option key={item.RAPI_SBEZEI} value={index}
                          >
                            {item.RAPI_SBEZEI}
                          </option>
                        ))}
                      </select>)
                        : (
                          <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressStateDescription}</span>
                        )
                      }
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )
                      }
                    </div>
                  </div>

                </div>

                <div className='row margin-settings'>
                  {/* Registered Office Address Pincode Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressPincode">
                      Pincode
                    </label>
                  </div>

                  {/* Registered Office Address Pincode Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressPincode"
                      name="registeredOfficeAddressPincode"
                      value={introductionData.registeredOfficeAddressPincode}
                      onChange={handlePinCodeChange}
                      pattern="[0-9]*"
                      maxLength="6"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressPincode}</span>
                    )
                    }
                  </div>

                </div>

                <div className='row margin-settings'>
                  {/* Brief Introduction Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="briefIntroduction">
                      Brief Introduction
                    </label>
                  </div>
                  <div className='col-7 col-md-10 text-left-registration'>
                    {isEditMode ? (<textarea
                      className="form-control select-input-registration"
                      id="briefIntroduction"
                      name="briefIntroduction"
                      value={introductionData.briefIntroduction || ''}
                      onChange={handleIntroductionInputChange}
                      maxLength="1000"
                      // rows={Math.ceil(introductionData.briefIntroduction.length / 140)}
                      rows={Math.ceil((introductionData.briefIntroduction?.length || 0) / 140)}
                      cols="140"
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.briefIntroduction || 'No Data available'}</span>
                    )}

                    {introductionData.briefIntroduction.length >= 1000 && (
                      <div className="tooltip">
                        <span className="tooltiptext">Maximum 1000 characters allowed.</span>
                      </div>
                    )}
                  </div>
                </div>

                {/*Finance Details */}
                <div className="sub-headings margin-settings">
                  <h6>Finance Manager Details</h6>
                </div>

                <div className="row margin-settings">
                  {/* Finance Manager Name */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerName">
                      Name
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="financeManagerName"
                      name="financeManagerName"
                      placeholder="Enter Finance Manager Name"
                      value={introductionData.financeManagerName}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerName}</span>
                    )}
                  </div>

                  {/*Finance Manager Mobile Number */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerPhoneNumberCountryCode">
                      Mobile Number
                    </label>
                  </div>

                  {/* <div className='col-1 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="financeManagerPhoneNumberCountryCode" name="financeManagerPhoneNumberCountryCode" onChange={handleSelectCountryCodeChange} value={selectedManagerPhoneCountryCode}
                        className='form-control select-input-registration custom-select-monospace'>
                        <option value="">Select an option</option>
                        {[...distinctCountryCodeMap].map(([key, value], index) => (
                          <option key={index} value={`+${value}`}>
                            {`${key}`.padEnd(4, ' ')} - {`+${value}`}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.financeManagerPhoneNumberCountryCode} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div> */}

                  <div className='col-1 custom-select-container text-left-registration'>
                    {/* Country Code */}
                    <div className='custom-select-wrapper'>
                      {/* <span className='view-mode-stylings'> {introductionData.vendorPrimaryMobileCountryCode} </span> */}
                      {isEditMode ? (<input
                        type="text"
                        id="financeManagerPhoneNumberCountryCode"
                        className='form-control select-input-registration'
                        name="financeManagerPhoneNumberCountryCode"
                        // value={introductionData.financeManagerPhoneNumberCountryCode}
                        value={introductionData.financeManagerPhoneNumberCountryCode || '+91'}
                        // onChange={handleIntroductionInputChange}
                        placeholder="Country Code"
                        // minLength={10}
                        // maxLength={10}
                        disabled={true}
                      />
                      )
                        : (
                          <span className='view-mode-stylings'>{introductionData.financeManagerPhoneNumberCountryCode || '+91'}</span>
                        )}
                    </div>
                  </div>


                  <div className="col-6 col-md-3 text-left-registration">
                    {isEditMode ? (
                      <input
                        type="tel"
                        id="financeManagerPhoneNumber"
                        className='form-control select-input-registration'
                        name="financeManagerPhoneNumber"
                        value={introductionData.financeManagerPhoneNumber}
                        onChange={handlePhoneInputChange}
                        placeholder="Manager Phone Number"
                        minLength={10}
                        maxLength={10}
                      />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerPhoneNumber}</span>
                    )
                    }
                  </div>

                </div>

                <div className="row margin-settings">
                  {/* Finance Manager Email */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerEmail">
                      E-mail
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="email"
                      className={`form-control select-input-registration ${!isValidEmail ? 'is-invalid' : ''
                        }`}
                      id="financeManagerEmail"
                      name="financeManagerEmail"
                      placeholder="Enter Finance Manager Email"
                      value={introductionData.financeManagerEmail}
                      onChange={handleIntroductionInputChange}
                      onBlur={handleEmailBlur}
                      maxLength="50"
                      required
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerEmail}</span>
                    )}
                    {!isValidEmail && (
                      <div className="invalid-feedback">Invalid email format (should contain '@')</div>
                    )}
                  </div>
                </div>

              </div>

            )}

            {currentStep === 2 && (
              <div className="table1-registration">
                {/* Country Of Tax Residency */}
                <div className="row">
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    {/* Country Of Tax Residency Label */}
                    <label htmlFor="countryOfTaxResidency">
                      Country of Tax Residency
                    </label>
                  </div>
                  {/* Country Of Tax Residency Input */}

                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="countryOfTaxResidency" onChange={handleSelectCountryOfTaxResidencyChange} value={selectedAPICountryOfTaxResidencyValue}
                        className='form-control select-input-registration'>
                        <option value="">Select an option</option>
                        {[...distinctCountryMap].map(([key, value], index) => (
                          <option key={index} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {kyvData.countryOfTaxResidencyLabel} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* Date of Incorporation/Birth */}
                <div className="row mt-3">
                  {/* Date of Incorporation/Birth Label */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="dateOfIncoperation">
                      Date of Incorporation/Birth
                    </label>
                  </div>
                  {/* Date of Incorporation/Birth Label */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="date"
                      className="form-control select-input-registration"
                      id="dateOfIncorporation"
                      name="dateOfIncorporation"
                      value={kyvData.dateOfIncorporation}
                      onChange={handleKYVInputChange}
                      max={getCurrentDate()}
                    />) : (
                      <span className='view-mode-stylings'>{formatDateForDisplay(kyvData.dateOfIncorporation)}</span>
                    )}
                  </div>
                </div>

                {/* PAN Number */}
                <div className='row mt-3'>
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="PANNumber">
                      PAN Number
                    </label>
                  </div>
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="PANNumber"
                      name="PANNumber"
                      placeholder="Enter your PAN Number"
                      value={kyvData.PANNumber}
                      onChange={handleKYVInputChange}
                      minLength="10"
                      maxLength="10"
                    />) : (
                      <span className='view-mode-stylings'>{kyvData.PANNumber}</span>
                    )}
                    {errorMessages.PANError && <div className="error-message">{errorMessages.PANError}</div>}
                  </div>
                </div>

                {/* Category/Type Of Work */}
                <div className='row mt-3'>
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="typeOfWork">
                      Category/ Type of Work
                    </label>
                  </div>
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="typeOfWork" name="typeOfWork" className="form-control select-input-registration"
                        onChange={handleKYVInputChange} value={kyvData.typeOfWork}
                      >
                        <option value="">Select Option</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="urd">URD</option>
                        <option value="wc">WC</option>
                        <option value="endCustomer">End Customer</option>
                        <option value="govt">Govt</option>
                      </select>) : (
                        <span className='view-mode-stylings'>{kyvData.typeOfWork}</span>
                      )}
                      {
                        isEditMode ? (
                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* CIN Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="CINNumber">
                        CIN Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ? (<input
                        type="text"
                        className=" form-check form-control select-input-registration"
                        id="CINNumber"
                        name="CINNumber"
                        placeholder="Enter CIN Number"
                        minLength={21}
                        maxLength={21}
                        value={kyvData.CINNumber}
                        onChange={handleKYVInputChange}
                        disabled={kyvData.isCINNotRegisteredChecked || kyvData.isCINNotApplicableChecked}
                      />) : (
                        kyvData.CINStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.CINNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.CINStatus}</span>
                        )
                      )
                      }
                      {errorMessages.CINError && <div className="error-message">{errorMessages.CINError}</div>}
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2 form-check"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="CINNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="CINNotRegisteredChecked"
                          name="isCINNotRegisteredChecked"
                          checked={kyvData.isCINNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="CINNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="CINNotApplicableChecked"
                          name="isCINNotApplicableChecked"
                          checked={kyvData.isCINNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>) : (
                    <span></span>
                  )}
                </div>

                {/* GST Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="GSTNumber">
                        GST Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ? (<input
                        type="text"
                        className="form-check form-control select-input-registration"
                        id="GSTNumber"
                        name="GSTNumber"
                        placeholder="Enter GST Number"
                        value={kyvData.GSTNumber}
                        onChange={handleKYVInputChange}
                        minLength={15}
                        maxLength={15}
                        disabled={kyvData.isGSTNotRegisteredChecked || kyvData.isGSTNotApplicableChecked}
                      />) :
                        (
                          kyvData.GSTStatus.toUpperCase() === "FILLED" ? (
                            <span className='view-mode-stylings'>{kyvData.GSTNumber}</span>
                          ) : (
                            <span className='view-mode-stylings'>{kyvData.GSTStatus}</span>
                          )
                        )}
                      {errorMessages.GSTError && <div className="error-message">{errorMessages.GSTError}</div>}
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="GSTNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="GSTNotRegisteredChecked"
                          name="isGSTNotRegisteredChecked"
                          checked={kyvData.isGSTNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="GSTNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="GSTNotApplicableChecked"
                          name="isGSTNotApplicableChecked"
                          checked={kyvData.isGSTNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>) : (
                    <span></span>)}

                </div>

                {/* UDYAM Number */}
                <div className="d-flex flex-column mt-3 mb-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="udyamNumber">
                        UDYAM Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ?
                        (
                          <>
                            <input
                              type="text"
                              className="form-check form-control select-input-registration"
                              id="udyamNumber"
                              name="udyamNumber"
                              placeholder="Enter UDYAM Number"
                              value={kyvData.udyamNumber}
                              onChange={handleKYVInputChange}
                            />
                          </>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.udyamNumber}</span>
                        )
                      }
                    </div>
                  </div>

                </div>

                {/* EPF Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="EPFNumber">
                        EPF Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ?
                        (
                          <>
                            <input
                              type="text"
                              className="form-check form-control select-input-registration"
                              id="EPFNumber"
                              name="EPFNumber"
                              placeholder="Enter EPF Number"
                              value={kyvData.EPFNumber}
                              onChange={handleKYVInputChange}
                              minLength={22}
                              maxLength={22}
                              disabled={kyvData.isEPFNotRegisteredChecked || kyvData.isEPFNotApplicableChecked}
                            />
                            {errorMessages.EPFError && <div className="error-message">{errorMessages.EPFError}</div>}
                          </>
                        ) : (
                          kyvData.EPFStatus.toUpperCase() === "FILLED" ? (
                            <span className='view-mode-stylings'>{kyvData.EPFNumber}</span>
                          ) : (
                            <span className='view-mode-stylings'>{kyvData.EPFStatus}</span>
                          )
                        )
                      }
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="EPFNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="EPFNotRegisteredChecked"
                          name="isEPFNotRegisteredChecked"
                          checked={kyvData.isEPFNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="EPFNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="EPFNotApplicableChecked"
                          name="isEPFNotApplicableChecked"
                          checked={kyvData.isEPFNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>
                  ) : (
                    <span></span>
                  )}

                </div>

                {/* ESIC Number */}

                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="ESICNumber">
                        ESIC Number
                      </label>
                    </div>
                    <div className='col-7 col-md-4 text-left-registration'>
                      {isEditMode ? (
                        <>
                          <div className="d-flex flex-column form-check">
                            <div>
                              <input
                                type="tel"
                                className="form-control select-input-registration"
                                id="ESICNumber"
                                name="ESICNumber"
                                placeholder="Enter ESIC Number"
                                value={kyvData.ESICNumber}
                                onChange={handleKYVInputChange}
                                minLength={17}
                                maxLength={17}
                                disabled={kyvData.isESICNotApplicableChecked || kyvData.isESICNotRegisteredChecked}
                              />
                              {errorMessages.ESICError && <div className="error-message">{errorMessages.ESICError}</div>}
                            </div>
                          </div>
                        </>) : (
                        kyvData.ESICStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.ESICNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.ESICStatus}</span>
                        )
                      )
                      }

                      <div className="row">
                        <div className="col-md-6">
                          {isEditMode ? (
                            <div className="form-check text-center">
                              <label className="form-check-label check-box-styling" htmlFor="ESICNotRegisteredChecked">
                                Not Registered
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="ESICNotRegisteredChecked"
                                  name="isESICNotRegisteredChecked"
                                  checked={kyvData.isESICNotRegisteredChecked}
                                  onChange={handleKYVInputChange}
                                />
                              </label>
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </div>

                        <div className="col-md-6">
                          {isEditMode ? (
                            <div className="form-check text-center">
                              <label className="form-check-label check-box-styling" htmlFor="ESICNotApplicableChecked">
                                Not Applicable
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="ESICNotApplicableChecked"
                                  name="isESICNotApplicableChecked"
                                  checked={kyvData.isESICNotApplicableChecked}
                                  onChange={handleKYVInputChange}
                                />
                              </label>
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>


                    </div>


                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="ESICRelatedDistrict">
                        District
                      </label>
                    </div>

                    <div className="col-7 col-md-4 form-check text-left-registration">
                      {isEditMode ? (
                        <input
                          type="text"
                          id="ESICRelatedDistrict"
                          className='form-control select-input-registration'
                          name="ESICRelatedDistrict"
                          value={kyvData.ESICRelatedDistrict}
                          onChange={handleKYVInputChange}
                          placeholder="ESIC Related District"
                          maxLength={80}
                          disabled={kyvData.isESICNotApplicableChecked || kyvData.isESICNotRegisteredChecked}
                        />
                      ) : (
                        kyvData.ESICStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.ESICRelatedDistrict}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.ESICStatus}</span>
                        )
                      )
                      }
                    </div>

                  </div>
                  <div className='d-flex'>
                    <div className="col-5 col-md-2"></div>

                  </div>

                </div>

                {/* TAN Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="TANNumber">
                        TAN Number
                      </label>
                    </div>
                    <div className='col-7 col-md-4 text-left-registration'>
                      {isEditMode ? (
                        <>
                          <div className="d-flex flex-column form-check">
                            <div>
                              <input
                                type="text"
                                className="form-control select-input-registration"
                                id="TANNumber"
                                name="TANNumber"
                                placeholder="Enter TAN Number"
                                value={kyvData.TANNumber}
                                onChange={handleKYVInputChange}
                                minLength={10}
                                maxLength={10}
                                disabled={kyvData.isTANNotApplicableChecked}
                              />
                              {errorMessages.TANError && <div className="error-message">{errorMessages.TANError}</div>}
                            </div>
                          </div>
                        </>) : (
                        kyvData.TANStatus === "Filled" ? (
                          <span className='view-mode-stylings'>{kyvData.TANNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.TANStatus}</span>
                        )
                      )}

                      {isEditMode ? (<div className="form-check text-center">
                        <label className="form-check-label check-box-styling" htmlFor="TANNotApplicableChecked">
                          Not Applicable
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="TANNotApplicableChecked"
                            name="isTANNotApplicableChecked"
                            checked={kyvData.isTANNotApplicableChecked}
                            onChange={handleKYVInputChange}
                          />
                        </label>
                      </div>
                      ) : (
                        <span></span>
                      )}

                    </div>


                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="TANMobileNumber">
                        Mobile Number
                      </label>
                    </div>

                    <div className="col-7 col-md-4 form-check text-left-registration">
                      {isEditMode ? (
                        <input
                          type="tel"
                          id="TANMobileNumber"
                          className='form-control select-input-registration'
                          name="TANMobileNumber"
                          value={kyvData.TANMobileNumber}
                          onChange={handlePhoneInputChange}
                          placeholder="TAN Associated Mobile Number"
                          pattern="[0-9]*"
                          minLength={10}
                          maxLength={10}
                          disabled={kyvData.isTANNotApplicableChecked}
                        />
                      ) : (
                        kyvData.isTANNotApplicableChecked ? (
                          <span className='view-mode-stylings'>{kyvData.TANNumber}</span>
                        ) : <span className='view-mode-stylings'>NA</span>
                      )}
                    </div>

                  </div>
                  <div className='d-flex'>
                    <div className="col-5 col-md-2"></div>

                  </div>

                </div>
              </div>

            )}

            {currentStep === 3 && (
              <div className="table1-registration">

                <div className="sub-headings margin-settings">
                  <h6>Account Details</h6>
                </div>

                <div className='row margin-settings'>
                  {/* Swift/ IFSC Code */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="swiftCode" className="form-label-styling-registration">
                      IFSC Code
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="swiftCode"
                      name="swiftCode"
                      placeholder="Enter Swift/IFSC Code"
                      value={bankAccountData.swiftCode}
                      onChange={handleBankAccountInputChange}
                      minLength="8"
                      maxLength="11"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.swiftCode}</span>
                    )}
                  </div>

                  {/* Bank Account Holder Name */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankAccountHolderName" className="form-label-styling-registration">
                      Account Holder Name
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAccountHolderName"
                      name="bankAccountHolderName"
                      placeholder="Enter Account Holder Name"
                      value={bankAccountData.bankAccountHolderName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAccountHolderName}</span>
                      )}
                  </div>
                  {/* Vendor's Bank Account Number */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankAccountNumber" className="form-label-styling-registration">
                      Account Number
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="number"
                      className="form-control select-input-registration"
                      id="bankAccountNumber"
                      name="bankAccountNumber"
                      placeholder="Enter Account Number"
                      value={bankAccountData.bankAccountNumber}
                      onChange={handleBankAccountInputChange}
                      minLength={12}
                      maxLength={18}
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.bankAccountNumber}</span>
                    )}
                    {bankAccountNumberError && <span style={{ color: 'red', fontSize: '10px' }}>{bankAccountNumberError}</span>}
                  </div>
                </div>

                <div className="sub-headings margin-settings">
                  <h6>Bank Details</h6>
                </div>

                <div className='row margin-settings'>
                  {/* Bank Name */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankName" className="form-label-styling-registration">
                      Bank Name
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankName"
                      name="bankName"
                      value={bankAccountData.bankName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.bankName}</span>
                    )}
                  </div>

                  {/* Bank Branch */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="branchName" className="form-label-styling-registration">
                      Bank Branch
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="branchName"
                      name="branchName"
                      value={bankAccountData.branchName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.branchName}</span>
                    )}
                  </div>

                  {/* Bank ID */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankID" className="form-label-styling-registration">
                      Bank ID
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankID"
                      name="bankID"
                      value={bankAccountData.bankID}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.bankID}</span>
                    )}
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Bank Control Key */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankControlKey" className="form-label-styling-registration">
                      Control Key
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankControlKey"
                      name="bankControlKey"
                      value={bankAccountData.bankControlKey}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankControlKey}</span>
                      )}
                  </div>

                  {/* Bank Country Key */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankCountryKey" className="form-label-styling-registration">
                      Bank Country Key
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankCountryKey"
                      name="bankCountryKey"
                      value={bankAccountData.bankCountryKey}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankCountryKey}</span>
                      )}
                  </div>

                  {/* Bank Address Region */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankAddressRegion" className="form-label-styling-registration">
                      Region
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressRegion"
                      name="bankAddressRegion"
                      value={bankAccountData.bankAddressRegion}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressRegion}</span>
                      )}
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Bank Street */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankAddressStreet" className="form-label-styling-registration">
                      Street
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressStreet"
                      name="bankAddressStreet"
                      value={bankAccountData.bankAddressStreet}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressStreet}</span>
                      )}
                  </div>

                  {/* City */}
                  <div className="col-5 col-md-2 text-right">
                    <label htmlFor="bankAddress" className="form-label-styling-registration">
                      City
                    </label>
                  </div>
                  <div className="col-7 col-md-2 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressCity"
                      name="bankAddressCity"
                      value={bankAccountData.bankAddressCity}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressCity}</span>
                      )}
                  </div>
                </div>

                {/* <div className='row margin-settings'>
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAddress" className="form-label-styling-registration">
                      City
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressCity"
                      name="bankAddressCity"
                      value={bankAccountData.bankAddressCity}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressCity}</span>
                      )}
                  </div>
                </div> */}
              </div>
            )}

            {currentStep === 4 && (
              <div className="table1-registration">

                {/* Note Information */}
                <div className="row margin-settings">
                  <div className="col-12 document-note-bg">
                    <p className='document-note-settings'>Note: Only Image or PDF Documents are accepted. Maximun file size per Document is <b style={{ color: "red" }}>5MB</b></p>
                  </div>
                </div>

                <FileUploadForm />

              </div>
            )}

            <div className="text-center mb-4">
              {currentStep === 1 && (
                <div>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isIntroductionData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveIntroductionDataToDatabase}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  <div>
                    {isIntroductionDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isIntroductionDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>

                </div>
              )}

              {(currentStep === 2) && (
                <>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isKYVData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveKYVDataToDatabase}>
                    Save
                  </button>
                  ) : (
                    <span></span>
                  )}

                  <div>
                    {isKYVDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isKYVDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>

                </>
              )}

              {(currentStep === 3) && (
                <>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isBankData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveBankInformationDataToDatabase}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  <div>
                    {isBankDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isBankDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>
                </>
              )}

              {currentStep === 4 && (
                <>
                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleNext}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  {isEditMode ? (<button type="submit" className="btn btn-primary button-registration" id="submitButton" onClick={handleSubmission} disabled={!isEditMode}>
                    Submit
                  </button>) : (
                    <span></span>
                  )}

                </>
              )}

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Submit;