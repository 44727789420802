import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPatil.css';
import 'font-awesome/css/font-awesome.min.css';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useVendorContext } from './VendorContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function LoginPatil({ onSuccessfulLogin }) {
  const navigate = useNavigate();
  const { setVendorNumber } = useVendorContext();
  const [formData, setFormData] = useState({
    userId: '',
    password: '',
    captcha: '',
  });

  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initializeCaptcha = (ctx) => {
    setUserInput('');
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const generateCaptchaText = () => {
    let captcha = '';
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha.split('').sort(
      () => Math.random() - 0.5).join('');
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor
      (Math.random() * (max - min + 1) + min));

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = '20px Roboto Mono';
      ctx.fillStyle = textColors[Math.floor(
        Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate(-1);
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  var [errorMessages, setErrorMessages] = useState({
    recaptcha: '',
    userNotRegistered: '',
    password: '',
    networkError: '',
    otherErrors: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {
    //  await axios.post('http://localhost:3000/api/data', { formData });
    // } catch (error) {
    //   console.error(error);
    // }

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
    console.log(username.length, "len of username", password.length, "len of pass");
    // const username = "SRIAABAP";
    // const password = "Pril@12345";
    console.log(username, password);
    const basicAuth = 'Basic ' + btoa(username + ':' + password);
    let apiUrl = ``;
    if (formData.userId.includes("@")) {
      apiUrl = `${process.env.REACT_APP_API_URL_EMAIL}${formData.userId}&AUTO_PASSWORD=${formData.password}`;
    }
    else {
      // apiUrl=`${process.env.REACT_APP_API_URL_USER}${formData.userId}&AUTO_PASSWORD=${formData.password}`;
      apiUrl = `${process.env.REACT_APP_API_URL_USER}${formData.userId}`;
    }

    if (!formData.userId.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: '',
        password: '',
        networkError: '',
        userNotRegistered: 'Please Enter UserId / Email Address',
        otherErrors: '',
      }));
    }

    else if (!formData.password.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: '',
        networkError: '',
        password: 'Please Enter Password',
        userNotRegistered: '',
        otherErrors: '',
      }));
    }

    else if (!formData.captcha.trim()) {
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        recaptcha: 'Please Enter Captcha',
        networkError: '',
        password: '',
        userNotRegistered: '',
        otherErrors: '',
      }));
    }

    else {
      try {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          recaptcha: '',
          password: '',
          networkError: '',
          userNotRegistered: '',
          otherErrors: '',
        }));
        setLoading(true);
        console.log("Spin");
        console.log("API URL is: ", apiUrl);
        console.log("User Login", basicAuth);
        console.log("formData", formData);
        // console.log("atob", atob(basicAuth));
        console.log("API URL is : ", apiUrl);
        const response = await axios.get(
          apiUrl,
          {
            headers: {
              'Authorization': basicAuth,
              // 'Access-Control-Allow-Origin': 'http://localhost:3000',
              'Content-Type': 'application/x-www-form-urlencoded',
              // 'Access-Control-Allow-Credentials': true,
              'X-CSRF-Token': 'Fetch'
              // 'Access-Control-Allow-Methods': '*',
              // 'Access-Control-Allow-Headers': '*',
            },
            // auth: {           
            //   username: username,           
            //   password: password, 
            // },
            // withCredentials: true,
          }
        );

        console.log("Hi");
        console.log("API response Headers are:", response.headers['x-csrf-token']);
        const token = response.headers['x-csrf-token'];
        const new_token = response.headers['csrf_token'];
        console.log("new_token value is: ", new_token);

        // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content') || 
        //           (document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1]);
        // console.log("CSRF token from Queryselector is: ", csrfToken);

        if (response.status === 200) {
          const apiData = await response.data;
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            networkError: '',
          }));
          if (apiData.AUTO_USER && apiData.AUTO_PASSWORD === formData.password) {
            const autoUserFromApi = apiData.AUTO_USER;
            const vendorStatusFromAPI = apiData.VEND_STATUS;
            setVendorNumber(apiData.AUTO_USER, apiData.VEND_STATUS);
            // localStorage.setItem('token', 'your_token_value');/
            // Set token
            localStorage.setItem('csrfToken', token);

            if (formData.captcha === captchaText) {
              setErrorMessages((prevErrorMessages) => ({
                ...prevErrorMessages,
                recaptcha: '',
                networkError: '',
                password: '',
                userNotRegistered: '',
                otherErrors: '',
              }));
              onSuccessfulLogin(autoUserFromApi, vendorStatusFromAPI);
            }

            else {
              setErrorMessages((prevErrorMessages) => ({
                ...prevErrorMessages,
                recaptcha: 'Please Enter Correct Captcha',
                userNotRegistered: '',
                password: '',
                otherErrors: '',
                networkError: '',
              }));
              const canvas = canvasRef.current;
              const ctx = canvas.getContext('2d');
              initializeCaptcha(ctx);
            }

          }

          else if (!apiData.VENDOR_NO) {
            setErrorMessages((prevErrorMessages) => ({
              ...prevErrorMessages,
              recaptcha: '',
              password: '',
              userNotRegistered: 'User Not Found',
              otherErrors: '',
              networkError: '',
            }));
          }

          else if (apiData.AUTO_PASSWORD !== formData.password) {
            setErrorMessages((prevErrorMessages) => ({
              ...errorMessages,
              recaptcha: '',
              password: 'Password is Incorrect, Please Check',
              userNotRegistered: '',
              otherErrors: '',
              networkError: '',
            }));
          }

          else {
            alert('Authentication failed');
          }
        }

        else if (response.status === 401) {
          setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            recaptcha: '',
            networkError: '',
            password: '',
            userNotRegistered: '',
            otherErrors: 'Error while Authenticating',
          }));
        }
      }
      catch (error) {
        console.log("Error message is: ", error.message);
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          networkError: error.message,
        }));
      }
      finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}
      <div className={`container-fluid bg-login ${loading ? 'blur' : ''}`} id="mainScreen">
        <div className='row bg-row d-flex justify-content-center align-items-center vh-100'>
          <div className="login-patil-container">
            <div className="login-card-header">
              <h5 className='login-card-heading'>Vendor Onboarding</h5>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-align">
                <div className="form-group">
                  <label htmlFor="userId" className="login-left-align-label mb-2">
                    User ID/ E-Mail ID
                  </label>
                  <input
                    type="text"
                    className="form-control login-input-field"
                    id="userId"
                    name="userId"
                    placeholder="Enter User ID"
                    value={formData.userId}
                    onChange={handleInputChange}
                    maxLength="50"
                    autoComplete='user-name'
                  />
                  {errorMessages.userNotRegistered && <p className="error-message">{errorMessages.userNotRegistered}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="password" className="login-left-align-label mt-2 mb-2">
                    Password
                  </label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control login-input-field"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      minLength="8"
                      maxLength="50"
                      autoComplete='current-password'
                    />
                    <span
                      className={`password-toggle-icon ${showPassword ? 'visible' : 'hidden'}`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </span>
                  </div>
                </div>
                {errorMessages.password && <p className="error-message">{errorMessages.password}</p>}
              </div>
              <div className="mt-3">
                <div className="recaptcha-container mt-5">
                  {/* <Captcha /> */}
                  <div>
                    <div className="wrapper">
                      <canvas ref={canvasRef}
                        width="200"
                        height="50"
                      >

                      </canvas>
                      <button id="reload-button" type="button" onClick={
                        () => initializeCaptcha(
                          canvasRef.current.getContext('2d'))} className="reload-button">
                        Reload
                      </button>
                    </div>
                    <div className='wrapper'>
                      <input
                        type="text"
                        id="user-input"
                        name="captcha"
                        placeholder="Enter the Captcha text"
                        value={formData.captcha}
                        onChange={handleUserInputChange} />
                    </div>
                    {errorMessages.recaptcha && <p className="error-message">{errorMessages.recaptcha}</p>}

                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mt-3 relativeness" disabled={loading}>
                Login
              </button>

              {errorMessages.networkError && <p className="error-message">{errorMessages.networkError}</p>}
              {errorMessages.otherErrors && <p className="error-message">{errorMessages.otherErrors}</p>}

              {/* <div className="mt-2">
                <Link to="/forgot-password" target="_self">
                  <p className='forget-password-label'>Forgot Password</p>
                </Link>
              </div> */}
            </form>

            <div className='container-fluid mt-3'>
              <div className='row bg-row'>
                <div className="d-flex justify-content-center login-patil-icons">
                  <span className='patil-footer-description'>Connect with Us On : </span>
                  <div className="icon-space">
                    <a href="https://www.facebook.com/patilgroup/" target="_self">
                      <i className="fa fa-2x fa-brands fa-facebook login-facebook-icon"></i>
                    </a>
                  </div>
                  <div className="icon-space">
                    <a href="https://www.linkedin.com/company/patil-group/?originalSubdomain=in" target="_self" rel="noopener noreferrer">
                      <i className="fa fa-2x fa-brands fa-linkedin login-linkedin-icon" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  );
}

export default LoginPatil;